export default [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/components/Login.vue"),
  },
  {
    path: "/sso/:token",
    name: "sso",
    component: () =>
        import(/* webpackChunkName: "login" */ "@/components/SSO.vue"),
  },
  {
    path: "/verify/:token",
    name: "verify",
    component: () =>
      import(/* webpackChunkName: "verify" */ "@/components/Verify.vue"),
  },
  {
    path: "/forgot",
    name: "forgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "@/components/ForgotPassword.vue"
      ),
  },
  {
    path: "/reset/:token",
    name: "resetPassword",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "@/components/ResetPassword.vue"
      ),
  },
  {
    path: "/redirect/:redirect",
    name: "redirect",
    component: () =>
      import(/* webpackChunkName: "redirect" */ "@/components/Login.vue"),
  },
];
