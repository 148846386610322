export default [
  {
    path: "/admin/tenants",
    name: "admin-tenants",
    meta: {
      requiresAuth: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_TECH_1", "ROLE_TECH_2"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-tenants" */ "@/components/adminTenants/AdminTenants.vue"
      ),
  },
  {
    path: "/admin/users",
    name: "admin-users",
    meta: {
      requiresAuth: true,
      requiresTenant: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPERVISOR", "ROLE_TECH_1", "ROLE_TECH_2"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-users" */ "@/components/adminUsers/AdminUsers.vue"
      ),
  },
  {
    path: "/admin/buildings",
    name: "admin-buildings",
    meta: {
      requiresAuth: true,
      requiresTenant: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPERVISOR"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-buildings" */ "@/components/adminBuildings/AdminBuildings.vue"
      ),
  },
  {
    path: "/admin/floors",
    name: "admin-floors",
    meta: {
      requiresAuth: true,
      requiresTenant: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPERVISOR"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-floors" */ "@/components/adminFloors/AdminFloors.vue"
      ),
  },
  {
    path: "/admin/questionnaires",
    name: "admin-questionnaires",
    meta: {
      requiresAuth: true,
      requiresTenant: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPERVISOR"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-questionnaires" */ "@/components/adminQuestionnaires/AdminQuestionnaires.vue"
      ),
  },
  {
    path: "/admin/tests",
    name: "admin-tests",
    meta: {
      requiresAuth: true,
      grantedRoles: ["ROLE_ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-tests" */ "@/components/adminTests/AdminTests.vue"
      ),
  },
  {
    path: "/admin/people",
    name: "admin-people",
    meta: {
      requiresAuth: true,
      requiresTenant: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPERVISOR", "ROLE_USER"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-people" */ "@/components/adminPeople/AdminPeople.vue"
      ),
  },
  {
    path: "/admin/logs",
    name: "admin-logs",
    meta: {
      requiresAuth: true,
      grantedRoles: ["ROLE_ADMIN"],
    },
    component: () =>
        import(
            /* webpackChunkName: "admin-tests" */ "@/components/adminLogs/AdminLogs.vue"
            ),
  },
];
