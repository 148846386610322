import axios from "axios";

export default {
  methods: {
    checkInQrCodeValue(hash, fromKiosk) {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
          (fromKiosk ? "/checkinKiosk/" : "/checkin/") +
        hash
      );
    },
    registrationQrCodeValue(hash) {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        "/registration/" +
        hash
      );
    },
    pinIdentificationQrCodeValue(hash) {
      return (
          window.location.protocol +
          "//" +
          window.location.host +
          "/pinIdentification/" +
          hash
      );
    },
    checkInLogo(id, version) {
      return (
        axios.defaults.baseURL +
        "/public/buildings/" +
        id +
        "/checkInLogoImage/" +
        version
      );
    },
  },
};
