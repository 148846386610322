import PhoneNumber from "awesome-phonenumber";

export default {
  methods: {
    formatPhoneNumber(n) {
      let formatted;
      if (n == null || n.length === 0) {
        formatted = '';
      } else {
        let pn = new PhoneNumber(n);
        if (pn.isValid()) {
          formatted = pn.getNumber("international");
        } else {
          formatted = n;
        }
      }
      return formatted;
    }
  }
};
