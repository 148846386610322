import * as types from "@/store/mutation-types";
import api from "@/services/api/exposed";
import { convertDateTimes } from "@/utils/utils";

const getters = {
  postponeCheckoutData: (state) => state.data,
  postponeCheckoutStatus: (state) => state.status,
  postponeCheckoutLoading: (state) => state.loading,
};

const actions = {
  getPostponeCheckOutData({ commit }, identifier) {
    return new Promise((resolve) => {
      api
        .getPostponeCheckoutData(identifier)
        .then((response) => {
          if (response.status === 200) {
            commit(types.EPOSTPONE_CHECKOUT_DATA, response.data);
            resolve();
          }
        })
        .catch(() => {
          commit(types.ERROR, "POSTPONE_CHECKOUT_VISIT_NOT_FOUND");
        });
    });
  },
  postponeCheckOut({ commit }, payload) {
    commit(types.EPOSTPONE_CHECKOUT_LOADING, true);
    return new Promise((resolve) => {
      api
        .postponeCheckout(payload.identifier, payload.scheduledDuration)
        .then((response) => {
          if (response.status === 200) {
            commit(types.EPOSTPONE_CHECKOUT_LOADING, false);
            commit(types.EPOSTPONE_CHECKOUT_STATUS, "success");
            resolve();
          }
        })
        .catch(() => {
          commit(types.EPOSTPONE_CHECKOUT_LOADING, false);
          commit(types.EPOSTPONE_CHECKOUT_STATUS, "error");
          resolve();
        });
    });
  },
};

const mutations = {
  [types.EPOSTPONE_CHECKOUT_DATA](state, data) {
    convertDateTimes(data);
    state.data = data;
  },
  [types.EPOSTPONE_CHECKOUT_STATUS](state, status) {
    state.status = status;
  },
  [types.EPOSTPONE_CHECKOUT_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
};

const state = {
  data: {},
  status: "pending",
  loading: false,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
