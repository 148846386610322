export const yesNoValues = function () {
  return [
    { label: this.$t("common.YES"), value: true },
    { label: this.$t("common.NO"), value: false },
  ];
};

export const temperatureMeasureScales = function () {
  return [
    { label: this.$t("common.CELSIUS"), value: "CELSIUS" },
    { label: this.$t("common.FAHRENHEIT"), value: "FAHRENHEIT" },
  ];
};

export function cToF(cTemp) {
  return (cTemp * 9 / 5 + 32).toFixed(2);
}

export function fToC(fTemp) {
  return ((fTemp - 32) * 5 / 9).toFixed(2);
}

export function durationValues() {
  return [
    "PT15M",
    "PT30M",
    "PT1H",
    "PT1H30M",
    "PT2H",
    "PT3H",
    "PT4H",
    "PT5H",
    "PT6H",
    "PT7H",
    "PT8H",
  ].map((val) => ({
    value: val,
    text: this.$t("checkInOut.duration." + val),
  }));
}
export const availableLanguageCodes = ['en', 'fr', 'es'];
export const availableLanguages = function() {
  return [
    {
      lang: "en",
      value: "ENGLISH",
      flag: "gb",
      class: "btnEN",
      label: this.$t('common.languages.ENGLISH')
    },
    {
      lang: "fr",
      value: "FRENCH",
      flag: "fr",
      class: "btnFR",
      label: this.$t('common.languages.FRENCH')
    },
    {
      lang: "es",
      value: "SPANISH",
      flag: "es",
      class: "btnES",
      label: this.$t('common.languages.SPANISH')
    },
  ];
}
