import * as types from "@/store/mutation-types";
import api from "@/services/api/config";
import { handleError } from "@/utils/utils.js";

const getters = {
  dateFormats: (state) => state.dateFormats,
  timeFormats: (state) => state.timeFormats,
  userRoles: (state) => state.userRoles,
};

const actions = {
  getPossibleValues({ commit }) {
    return new Promise((resolve, reject) => {
      api
          .getPossibleValues()
          .then((response) => {
            if (response.status === 200) {
              commit(types.DATE_FORMATS, response.data.dateFormats);
              commit(types.TIME_FORMATS, response.data.timeFormats);
              resolve();
            }
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
    });
  },
  getUserRoles({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getUserRoles()
        .then((response) => {
          if (response.status == 200) {
            commit(types.USER_ROLES, response.data);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
};

const mutations = {
  [types.DATE_FORMATS](state, dateFormats) {
    state.dateFormats = dateFormats;
  },
  [types.TIME_FORMATS](state, timeFormats) {
    state.timeFormats = timeFormats;
  },
  [types.USER_ROLES](state, roles) {
    state.userRoles = roles;
  },
};

const state = {
  dateFormats: [],
  timeFormats: [],
  userRoles: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
