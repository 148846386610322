export default [
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "@/components/checkInOut/CheckInOut.vue"
      ),
  },
];
