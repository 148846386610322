import * as types from "@/store/mutation-types";
import api from "@/services/api/verify";
import { buildSuccess, handleError } from "@/utils/utils.js";
import router from "@/router";

const getters = {
  userToVerify: (state) => state.userToVerify,
};

const actions = {
  getNotVerifiedUser({ commit }, token) {
    return new Promise((resolve, reject) => {
      api
        .getNotVerifiedUser(token)
        .then((response) => {
          if (response.status === 200) {
            commit(types.NOT_VERIFIED_USER, response.data);
            resolve();
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            commit(types.NOT_VERIFIED_USER, {});
            resolve();
          } else {
            handleError(error, commit, reject);
          }
        });
    });
  },
  sendVerify({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true);
      api
        .sendVerify(data)
        .then((response) => {
          if (response.status === 200) {
            // If user is logged in then update localstorage
            if (localStorage.getItem("user")) {
              const _user = JSON.parse(localStorage.getItem("user"));
              localStorage.setItem("user", JSON.stringify(_user));
              commit(types.SAVE_USER, _user);
              commit(types.NOT_VERIFIED_USER, {});
            }
            buildSuccess(
              {
                msg: "verify.EMAIL_VERIFIED",
              },
              commit,
              resolve,
              router.push({name: "login"})
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
};

const mutations = {
  [types.NOT_VERIFIED_USER](state, value) {
    state.userToVerify = value;
  },
};

const state = {
  userToVerify: {},
};

export default {
  state,
  getters,
  actions,
  mutations,
};
