import {yesNoValues} from "@/components/common/commons";
import {resolveRule} from "@/components/checkInOut/visitors";
import {questionAnswers} from "@/components/common/questionnaires";

export default {
    name: "CommonWizard",
    props: {
        loadedData: {
            type: Object,
            default: {},
        },
        checkIn: {
            type: Boolean,
            default: true
        },
        skipCheckInStep: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentStep: 1,
            meetingDuration: null,
            checkInForm: {},
            questionnairesAnswers: {},
            consentAccepted: false,
            emailMeActive: false,
            emailMeEmail: null,
            showModal: false,
            tempMeasure1: null,
            tempMeasure2: null,
            comments: null
        }
    },
    computed: {
        checkInFields() {
            if (this.loadedData && this.loadedData.checkInForm) {
                return Object.keys(this.loadedData.checkInForm);
            } else return [];
        },
        yesNoValues,
        stepsCount() {
            let count = this.skipCheckInStep ? 0 : 1;
            if (this.loadedData) {
                if (this.loadedData.questionnaires) {
                    count += this.loadedData.questionnaires.length;
                }
                if (this.consentOrEmailMeActive) {
                    count++;    //consent clause and/or email me
                }
                if ((this.loadedData.checkInTemperatureMeasuresEnabled && this.loadedData.allowCheckInManualTemperatureMeasure) || this.loadedData.commentsEnabled) {
                    count++;
                }
            }
            return count;
        },
        consentOrEmailMeActive() {
            return this.loadedData.consentClauseActive || this.loadedData.emailMeActive;
        }
    },
    methods: {
        questionAnswers,
        resolveRule,
        resolveVisitorType(char) {
            if (char === "v") {
                return "VISITOR";
            } else if (char === "c") {
                return "CONTRACTOR";
            } else if (char === "e") {
                return "EMPLOYEE";
            } else return null;
        },
        isQuestionSelectType(type) {
            return type === 'YESNO' || type === 'YESNONA';
        },
        finish() {
            const payload = {
                qrCodeKey: this.loadedData.qrCodeKey,
                scheduledDuration: this.meetingDuration,
                visitorType: this.loadedData.visitorType,
                checkInForm: this.checkInForm,
                questionnairesAnswers: this.questionnairesAnswers,
                publicIdentifier: this.loadedData.meetingIdentifier,
                tempMeasure1: this.tempMeasure1,
                tempMeasure2: this.tempMeasure2,
                comments: this.comments,
                personId: this.loadedData.personId,
                replaceOldVisit: this.loadedData.replaceOldVisit,
                emailMeActive: this.emailMeActive,
                emailMeEmail: this.emailMeEmail,
                id: this.loadedData.id
            };
            this.$emit("on-finish", payload);
        },
        next() {
            this.currentStep++;
        },
        back() {
            this.currentStep--;
        },
        initData() {
            this.currentStep = 1;
            this.meetingDuration = null;
            this.checkInForm = {};
            this.questionnairesAnswers = {};
            this.consentAccepted = false;
            this.showModal = false;
            this.tempMeasure1 = null;
            this.tempMeasure2 = null;
            this.comments = null;
        },
        cancel() {
            this.initData();
            this.$emit("on-cancel");
        },
        fillTemperature(temp1, temp2, comments) {
            // console.log('fillTemperature', temp1, temp2);
            this.tempMeasure1 = temp1;
            this.tempMeasure2 = temp2;
            this.comments = comments;
        }
    },
    watch: {
        loadedData: {
            async handler(newValue) {
                if (newValue.checkInAnswers && Object.keys(newValue.checkInAnswers).length > 0) {
                    Object.keys(newValue.checkInForm).forEach((key) => {
                        if (newValue.checkInForm[key].type === "BOOLEAN") {
                            this.checkInForm[key] = "true" === newValue.checkInAnswers[key];
                        } else {
                            this.checkInForm[key] = newValue.checkInAnswers[key];
                        }
                    });
                } else {
                    Object.keys(this.checkInForm).forEach((key) => {
                        if (this.checkInForm[key].type === "BOOLEAN") {
                            this.checkInForm[key] = false;
                        } else {
                            this.checkInForm[key] = '';
                        }
                    });
                }
                if (newValue.answeredQuestionnaires) {
                    Object.keys(newValue.answeredQuestionnaires).forEach((aq) => {
                        const questionnaire = newValue.questionnaires.find((q) => q.name === aq);
                        if (questionnaire) {
                            newValue.answeredQuestionnaires[aq].forEach((item) => {
                                const question = questionnaire.items.find(q => q.question === item.question);
                                if (question) {
                                    this.questionnairesAnswers[question.id] = item.answer;
                                    this.questionnairesAnswers = {
                                        ...this.questionnairesAnswers,
                                    }   //lol
                                }
                            })
                        }
                    });
                }
                this.meetingDuration = newValue.id ? newValue.scheduledDuration : newValue.defaultMeetingDuration;
                if (newValue.checkingBack && !newValue.fromKiosk) {
                    this.finish();
                }
            },
            immediate: true,
            deep: true
        }
    },
}
