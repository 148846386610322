import * as types from "@/store/mutation-types";
import api from "@/services/api/exposed";
import router from "@/router";

const getters = {
  registrationData: (state) => state.data,
  registrationStatus: (state) => state.status,
};

const actions = {
  getRegistrationData({ commit }, payload) {
    return new Promise((resolve) => {
      api
        .getRegistrationData(payload.hash, payload.visitorType)
        .then((response) => {
          if (response.status === 200) {
            commit(types.EREGISTRATION_DATA, response.data);
            resolve();
          }
        })
        .catch(() => {
          router.push({
            name: "about",
          });
        });
    });
  },
  register({ commit }, payload) {
    return new Promise((resolve) => {
      api
        .register(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.EREGISTRATION_STATUS, "success");
            resolve();
          }
        })
        .catch(() => {
          commit(types.EREGISTRATION_STATUS, "error");
          resolve();
        });
    });
  },
};

const mutations = {
  [types.EREGISTRATION_DATA](state, data) {
    state.data = data;
  },
  [types.EREGISTRATION_STATUS](state, status) {
    state.status = status;
  },
};

const state = {
  data: {},
  status: "pending",
};

export default {
  state,
  getters,
  actions,
  mutations,
};
