import * as types from "@/store/mutation-types";
import api from "@/services/api/adminTenants";
import { buildSuccess, convertDateTimes, handleError } from "@/utils/utils.js";

const getters = {
  tenants: (state) => state.tenants,
  totalTenants: (state) => state.totalTenants,
};

const actions = {
  getTenants({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getTenants(payload)
        .then((response) => {
          if (response.status == 200) {
            commit(types.TENANTS, response.data.docs);
            commit(types.TOTAL_TENANTS, response.data.totalDocs);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  editTenant({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const data = {
        id: payload.id,
        name: payload.name,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        state: payload.state,
        zip: payload.zip,
        phone: payload.phone,
        phone2: payload.phone2,
        fax: payload.fax,
        countryCode: payload.countryCode,
        email: payload.email,
        active: payload.active,
        licencesCount: payload.licencesCount
      };
      api
        .editTenant(data.id, data)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "common.SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  createTenantWithMaster({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
          .createTenantWithMaster(payload)
          .then(() => {
            buildSuccess(
                {
                  msg: "common.SAVED_SUCCESSFULLY",
                },
                commit,
                resolve
            );
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
    });
  },
  deleteTenant({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteTenant(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "common.DELETED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
};

const mutations = {
  [types.TENANTS](state, tenants) {
    convertDateTimes(tenants);
    state.tenants = tenants;
  },
  [types.TOTAL_TENANTS](state, value) {
    state.totalTenants = value;
  },
};

const state = {
  tenants: [],
  totalTenants: 0,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
