<template>
  <ValidationProvider :rules="(required ? 'duration' : '')" v-slot="{ valid }">
    <input type="hidden" :value="duration()" />
    <div class="duration">
      <div class="pb-0 v-input">
        <span :class="'v-label v-label--active' + (valid ? '' : ' red--text')" style="font-size:0.675rem;
      margin-top:1px;">{{ label }}&nbsp;<span v-if="required" class="red--text">*</span></span>
      </div>
      <div :class="'duration-inputs' + (valid ? '' : ' red--text')">
        <v-text-field class="mt-0 pt-0"
                      min="0"
                      v-model="internalModel.hours"
                      type="number"
                      :max="(maxHours ? maxHours : 24)"
                      :disabled="disabled"
                      :single-line="true"
                      @input="updateValue"
                      autocomplete="off"
                      :autofocus="autoFocus"
                      :hide-details="true"
                      @focus="$event.target.select()"
        />
        <span>:</span>
        <v-select class="mt-0 pt-0"
                  v-model="internalModel.minutes"
                  :disabled="disabled"
                  :items="minutesItems"
                  item-text="label"
                  item-value="value"
                  :hide-details="true"
                  :single-line="true"
                  @change="updateValue"
        />
      </div>
      <div class="v-text-field__details">
        <div class="v-messages theme--light error--text" role="alert">
          <div class="v-messages__wrapper">
            <div class="v-messages__message" v-if="! valid">{{
                $t('common.durationInput.error.greaterThanZero')
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import {parse, serialize} from 'tinyduration';

export default {
  name: "DurationInput",
  props: ["value", "label", "labelSuffix", "required", "disabled", "maxHours", "autoFocus"],
  data() {
    return {
      internalModel: {
        hours: 0,
        minutes: 0
      }
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.parseVal(this.value);
      }
    }
  },
  methods: {
    duration() {
      return this.internalModel.hours * 60 + this.internalModel.minutes;
    },
    evaluateVal() {
      return serialize({hours: this.internalModel.hours, minutes: this.internalModel.minutes})
    },
    parseVal() {
      const durationObj = parse(this.value);
      this.internalModel = {
        hours: durationObj.hours || 0,
        minutes: durationObj.minutes || 0
      }
    },
    updateValue() {
      if (this.internalModel.hours >= 0 && this.internalModel.minutes >= 0) {
        this.$emit('input', this.evaluateVal())
      }
    }
    // emits() {
    //   console.log('durationinput emits', this.required, this.internalModel,
    //       (this.internalModel.hours >= 0 && this.internalModel.minutes >= 0 &&
    //           (this.internalModel.hours + this.internalModel.minutes) > 0));
    //   this.durationError = this.required && !(this.internalModel.hours >= 0 && this.internalModel.minutes >= 0 && (this.internalModel.hours + this.internalModel.minutes) > 0);
    // }
  },
  computed: {
    minutesItems() {
      return [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map((val) => ({
        value: val,
        label: val + " " + this.$t("common.durationInput.minutes.label"),
      }));
    }
  },
  created() {
    if (this.value) {
      this.parseVal();
    }
  },
}
</script>

<style scoped>

</style>