import * as types from "@/store/mutation-types";
import router from "@/router";
import api from "@/services/api/auth";
import { buildSuccess, handleError } from "@/utils/utils.js";
import { addMinutes, format } from "date-fns";
import { store } from "@/store";

const MINUTES_TO_CHECK_FOR_TOKEN_REFRESH = 1440;
const MINUTES_TO_CHECK_FOR_TOKEN_REFRESH_FOR_TECH = 60;

const getters = {
    user: (state) => state.user,
    token: (state) => state.token,
    isTokenSet: (state) => state.isTokenSet,
    userFormatDateTime: (state) =>
        state.user
            ? state.user.dateFormat + " " + state.user.timeFormat
            : undefined,
    isAdmin: (state) => state.user && state.user.role === "ROLE_ADMIN",
    isTech: (state) => state.user && state.user.role.startsWith('ROLE_TECH'),
    isMaster: (state) => state.user && state.user.role === "ROLE_MASTER",
    isSupervisor: (state) => state.user && state.user.role === "ROLE_SUPERVISOR",
    isTech_1: (state) => state.user && state.user.role === "ROLE_TECH_1",
    isTech_2: (state) => state.user && state.user.role === "ROLE_TECH_2",
    userDateFormat: (state) => (state.user ? state.user.dateFormat : undefined),
    userTimeFormat: (state) => (state.user ? state.user.timeFormat : undefined),
    isAtLeastSupervisor: (state) => state.user && ["ROLE_SUPERVISOR", "ROLE_MASTER", "ROLE_ADMIN"].indexOf(state.user.role) > -1,
    redirectPath: (state) => state.redirectPath,
    userTimeNoSecFormat: (state) => (state.user ? state.user.timeFormat.replace(":ss", "") : undefined)
};

const afterLogin = function(dispatch, commit, resolve, response) {
    if (response.status === 200) {
        window.localStorage.setItem(
            "user",
            JSON.stringify(response.data.user)
        );
        window.localStorage.setItem(
            "token",
            JSON.stringify(response.data.token)
        );
        window.localStorage.setItem(
            "tokenExpiration",
            JSON.stringify(
                format(
                    addMinutes(new Date(), MINUTES_TO_CHECK_FOR_TOKEN_REFRESH),
                    "t"
                )
            )
        );
        dispatch("setLocale", response.data.user.userLocale);
        commit(types.SET_LOCALE, response.data.user.userLocale);
        commit(types.SAVE_USER, response.data.user);
        commit(types.SAVE_TOKEN, response.data.token);
        dispatch("setMyTenant");
        const componentToRedirect = response.data.user.role.startsWith('ROLE_TECH') ? 'admin-users' : 'checkinout';
        const redirectPath=store.getters.redirectPath
        if (redirectPath != null &&redirectPath != '') {
            // Redirect path
            commit(types.REDIRECT_PATH, "");
            buildSuccess(
                null,
                commit,
                resolve,
                router.push(redirectPath)
            );

        } else {
            //Direct default path
            buildSuccess(
                null,
                commit,
                resolve,
                router.push({
                    name: componentToRedirect,
                })
            );
        }
    }
}

const actions = {
    userLogin({dispatch, commit}, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .userLogin(payload)
                .then((response) => {
                    afterLogin(dispatch, commit, resolve, response);
                })
                .catch((error) => {
                    if (error.response.status === 403) {
                        commit(types.ERROR, "WRONG_PASSWORD");
                        commit(types.SHOW_LOADING, false);
                    } else {
                        handleError(error, commit, reject);
                    }
                });
        });
    },
    logInAs({dispatch, commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .logInAs(payload.email)
                .then((response) => {
                    window.localStorage.setItem("original_user",
                        window.localStorage.getItem("user")
                    );
                    window.localStorage.setItem(
                        "user",
                        JSON.stringify(response.data.user)
                    );
                    window.localStorage.setItem("original_token",
                        window.localStorage.getItem("token")
                    );
                    window.localStorage.setItem(
                        "token",
                        JSON.stringify(response.data.token)
                    );
                    window.localStorage.setItem("original_tokenExpiration",
                        window.localStorage.getItem("tokenExpiration")
                    )
                    window.localStorage.setItem(
                        "tokenExpiration",
                        JSON.stringify(
                            format(
                                addMinutes(new Date(), MINUTES_TO_CHECK_FOR_TOKEN_REFRESH_FOR_TECH),
                                "t"
                            )
                        )
                    );
                    commit(types.SAVE_ORIGINAL_USER_AND_TOKEN);
                    dispatch("setLocale", response.data.user.userLocale);
                    commit(types.SET_LOCALE, response.data.user.userLocale);
                    commit(types.SAVE_USER, response.data.user);
                    commit(types.SAVE_TOKEN, response.data.token);
                    dispatch("setMyTenant");
                    const componentToRedirect = response.data.user.role.startsWith('ROLE_TECH') ? 'admin-users' : 'checkinout';
                    console.log('redirect to ' + componentToRedirect);
                    buildSuccess(
                        {
                            msg: "login.LOGGED_IN_AS_SUCCESS",
                            params: [response.data.user.username]
                        },
                        commit,
                        resolve,
                        router.push({
                            name: componentToRedirect,
                        })
                    );
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    refreshToken({commit}) {
        return new Promise((resolve, reject) => {
            api
                .refreshToken()
                .then((response) => {
                    if (response.status === 200) {
                        window.localStorage.setItem(
                            "token",
                            JSON.stringify(response.data.token)
                        );
                        window.localStorage.setItem(
                            "tokenExpiration",
                            JSON.stringify(
                                format(
                                    addMinutes(new Date(), MINUTES_TO_CHECK_FOR_TOKEN_REFRESH),
                                    "t"
                                )
                            )
                        );
                        commit(types.SAVE_TOKEN, response.data.token);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    autoLogin({dispatch, commit}) {
        const user = JSON.parse(localStorage.getItem("user"));
        commit(types.SAVE_USER, user);
        commit(types.SAVE_TOKEN, JSON.parse(localStorage.getItem("token")));
        commit(types.SET_LOCALE, JSON.parse(localStorage.getItem("locale")));
        commit(types.SAVE_ORIGINAL_TOKEN, JSON.parse(localStorage.getItem("original_token")));
        commit(types.SAVE_ORIGINAL_USER, JSON.parse(localStorage.getItem("original_user")));
        dispatch("setMyTenant");
    },
    userLogout({commit}) {
        if (window.localStorage.getItem("original_token")) {
            window.localStorage.setItem("token", window.localStorage.getItem("original_token"));
            window.localStorage.setItem("tokenExpiration", window.localStorage.getItem("original_tokenExpiration"));
            window.localStorage.setItem("user", window.localStorage.getItem("original_user"));
            window.localStorage.removeItem("original_token");
            window.localStorage.removeItem("original_tokenExpiration");
            window.localStorage.removeItem("original_user");
            const previousUsername = getters.user.username;
            commit(types.RESTORE_ORIGINAL_USER_AND_TOKEN);
            const msg = {
                msg: "login.LOGGED_OUT_AS_SUCCESS",
                params: [previousUsername]
            };
            commit(types.SUCCESS, msg);
            router
                .push({
                    name: "admin-users",
                })
                .catch((err) => {
                    console.log(err.name);
                });
            location.reload();
        } else {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("tokenExpiration");
            window.localStorage.removeItem("user");
            commit(types.LOGOUT);
            router
                .push({
                    name: "login",
                })
                .catch((err) => {
                    console.log(err.name);
                });
        }
    },
    userLogoutSilently({commit}) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("tokenExpiration");
        window.localStorage.removeItem("user");
        commit(types.LOGOUT);
    },
    ssoLogin({dispatch, commit}, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .ssoLogin(payload)
                .then((response) => {
                    afterLogin(dispatch, commit, resolve, response)
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        commit(types.SHOW_LOADING, false);
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.replace({
                                name: 'login',
                            })
                        );
                    } else {
                        handleError(error, commit, reject);
                    }
                });
        });
    },
};

const mutations = {
    [types.SAVE_TOKEN](state, token) {
        state.token = token;
        state.isTokenSet = true;
    },
    [types.SAVE_ORIGINAL_TOKEN](state, token) {
        state.original.token = token;
    },
    [types.LOGOUT](state) {
        state.user = null;
        state.token = null;
        state.isTokenSet = false;
    },
    [types.SAVE_USER](state, user) {
        state.user = user;
    },
    [types.SAVE_ORIGINAL_USER](state, user) {
        state.original.user = user;
    },
    [types.SAVE_ORIGINAL_USER_AND_TOKEN](state) {
        state.original.token = state.token;
        state.original.user = state.user;
    },
    [types.RESTORE_ORIGINAL_USER_AND_TOKEN](state) {
        state.token = state.original.token;
        state.user = state.original.user;
        state.original = {
            user: null,
            token: null
        };
    },
    [types.SAVE_USER_PREFERENCES](state, preferences) {
        state.user.dateFormat = preferences.dateFormat;
        state.user.timeFormat = preferences.timeFormat;
        state.user.favouriteBuildingId = preferences.favouriteBuildingId;
    },
    [types.REDIRECT_PATH](state,path) {
        state.redirectPath = path;
    },
};

const state = {
    user: null,
    token: JSON.parse(!!localStorage.getItem("token")) || null,
    isTokenSet: !!localStorage.getItem("token"),
    original: {
        user: null,
        token: null,
    },
    redirectPath: ''
};

export default {
    state,
    getters,
    actions,
    mutations,
};
