import axios from "axios";

export default {
  userLogin(payload) {
    return axios.post("/login", payload).then(function (result) {
      result.data.user.role = result.data.user.authorities
        .map((elem) => elem.authority)
        .join(",");
      delete result.data.user.authorities;
      return result;
    });
  },
  logInAs(email) {
    const params = new URLSearchParams();
    params.append('username', email);
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    return axios.post("/login/impersonate", params, config).then(function (result) {
      result.data.user.role = result.data.user.authorities
          .map((elem) => elem.authority)
          .join(",");
      delete result.data.user.authorities;
      return result;
    });
  },
  refreshToken() {
    return axios.get("/token");
  },
  ssoLogin(payload) {
    return axios.get(`/sso/${payload.token}`).then(function (result) {
      result.data.user.role = result.data.user.authorities
          .map((elem) => elem.authority)
          .join(",");
      delete result.data.user.authorities;
      return result;
    });
  },
};
