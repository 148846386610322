import * as types from "@/store/mutation-types";
import api from "@/services/api/profile";
import { buildSuccess, handleError } from "@/utils/utils.js";

const getters = {
  profile: (state) => state.profile,
  userBuildings: (state) => state.userBuildings,
};

const actions = {
  getUserBuildings({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getUserBuildings()
        .then((response) => {
          if (response.status === 200) {
            if (response.data.length === 0) {
              commit(types.WARNING, {msg: "checkInOut.noBuildingAssigned"});
            }
            commit(types.PROFILE_BUILDINGS, response.data);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  changeMyPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true);
      api
        .changeMyPassword(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "myProfile.PASSWORD_CHANGED",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  getProfile({ commit }) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true);
      api
        .getProfile()
        .then((response) => {
          if (response.status === 200) {
            commit(types.FILL_PROFILE, response.data);
            buildSuccess(null, commit, resolve);
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  saveProfile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true);
      api
        .saveProfile(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.FILL_PROFILE, response.data);
            const preferences = {
              dateFormat: response.data.dateFormat,
              timeFormat: response.data.timeFormat,
              favouriteBuildingId: response.data.favouriteBuildingId,
            };
            commit(types.SAVE_USER_PREFERENCES, preferences);
            buildSuccess(
              {
                msg: "myProfile.PROFILE_SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  addProfileData({ commit }, data) {
    commit(types.ADD_PROFILE_DATA, data);
  },
};

const mutations = {
  [types.FILL_PROFILE](state, data) {
    state.profile = data;
  },
  [types.ADD_PROFILE_DATA](state, data) {
    switch (data.key) {
      case "firstName":
        state.profile.firstName = data.value;
        break;
      case "lastName":
        state.profile.lastName = data.value;
        break;
      case "phone":
        state.profile.phone = data.value;
        break;
      case "userLocale":
        state.profile.userLocale = data.value;
        break;
      case "dateFormat":
        state.profile.dateFormat = data.value;
        break;
      case "timeFormat":
        state.profile.timeFormat = data.value;
        break;
      case "favouriteBuildingId":
        state.profile.favouriteBuildingId = data.value;
        break;
      default:
        break;
    }
  },
  [types.PROFILE_BUILDINGS](state, buildings) {
    state.userBuildings = buildings;
    let names = {};
    buildings.forEach((b) => (names["b" + b.id] = b.name));
    state.buildingsNames = names;
  },
};

const state = {
  profile: {
    verified: false,
    name: "",
    email: "",
    phone: "",
  },
  userBuildings: [],
  buildingsNames: {},
};

export default {
  state,
  getters,
  actions,
  mutations,
};

export const getBuildingNameById = function (id) {
  return state.buildingsNames["b" + id];
};
