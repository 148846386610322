export default [
  {
    path: "/check-in-out",
    name: "checkinout",
    meta: {
      requiresAuth: true,
      requiresTenant: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPERVISOR", "ROLE_USER"],
    },
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/components/checkInOut/CheckInOut.vue"
      ),
  },
];
