<template>
  <v-app>
    <Toolbar v-if="!hideHeader" />
    <v-main :class="appBackground" style="margin-bottom: 40px">
      <v-dialog v-if="isAdmin || isTech" v-model="changeTenantDialog" max-width="400px">
        <template #activator="{ on }">
          <v-flex text-xs-center>
            <h2 class="mt-1 mb-1 title--text pointer" v-on="on" @click="changeTenantDialog = true">
              {{ myTenant.name }}
              <v-icon class="title--text pointer ml-2">mdi-sync</v-icon>
            </h2>
          </v-flex>
        </template>
        <v-card>
          <v-card-title class="dialog-header">{{ $t("common.SELECT_TENANT") }}</v-card-title>
          <v-card-text>
            <v-select v-model="tenantId" :items="allTenants" item-value="id" item-text="name" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="red lighten3" text @click="changeTenantDialog = false">
              {{ $t("common.CANCEL") }}
            </v-btn>
            <v-btn color="green" text @click="changeTenant">
              {{ $t('common.SELECT') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <loading />
      <v-container fluid>
        <transition name="fade" mode="out-in">
          <router-view v-if="contentAllowed" />
          <AccessDenied v-if="!contentAllowed"></AccessDenied>
        </transition>
      </v-container>
    </v-main>
    <Footer v-if="!hideHeader" />
  </v-app>
</template>

<script>
import Toolbar from "@/components/core/Toolbar.vue";
import Loading from "@/components/core/Loading.vue";
import Footer from "@/components/core/Footer.vue";
import { mapActions, mapGetters } from "vuex";
import AccessDenied from "@/components/AccessDenied";

export default {
  name: "App",
  metaInfo() {
    return {
      title: this.appTitle,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { name: "msapplication-TileColor", content: "#ffc40d" },
        { name: "theme-color", content: "#ffffff" },
        {
          name: "apple-mobile-web-app-title",
          content: this.appTitle,
        },
        { name: "application-name", content: this.appTitle },
      ],
      link: [
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/apple-touch-icon.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/favicon-32x32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/favicon-16x16.png",
        },
        { rel: "manifest", href: "/site.webmanifest" },
        { rel: "mask-icon", color: "#5bbad5", href: "/safari-pinned-tab.svg" },
        { rel: "favicon", href: "/favicon.ico" },
      ],
    };
  },
  components: {
    AccessDenied,
    Toolbar,
    Loading,
    Footer,
  },
  computed: {
    ...mapGetters(["user", "myTenant", "allTenants", "isAdmin", "isTech"]),
    appTitle() {
      return this.$store.getters.appTitle;
    },
    appBackground() {
      if (this.hideHeader)
        return this.$store.getters.appWithoutBackground;

      else
        return this.$store.getters.appBackground;
    }
  },
  data() {
    return {
      contentAllowed: true,
      changeTenantDialog: false,
      tenantId: null,
      hideHeader: false,
    }
  },
  watch: {
    $route(to) {
      if (to && to.meta && to.meta.grantedRoles && this.user && this.user.roles && to.meta.grantedRoles.indexOf(this.user.role) === -1) {
        this.contentAllowed = false;
      } else {
        this.contentAllowed = true;
      }

      if (to.name == 'admin-floorPrint') {
        this.hideHeader = true;
      }
      else {
        this.hideHeader = false;
      }

    },
    myTenant(value) {
      this.tenantId = value.id;
    }
  },
  methods: {
    ...mapActions(["doSelectTenant"]),
    async changeTenant() {
      this.changeTenantDialog = false;
      await this.doSelectTenant(this.tenantId);
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
