<template>
  <v-container fluid>
    <v-layout row wrap>
      <Heading :title="$t('accessDenied.title')" />
      <Description :description="$t('accessDenied.description')" />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t("accessDenied.title")} - %s`,
    };
  },
};
</script>
