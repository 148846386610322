import * as types from "@/store/mutation-types";
import checkInApi from "@/services/api/exposed";

const getters = {
    pinIdentificationStatus: (state) => state.status,
    pinIdentificationData: (state) => state.data
};

const actions = {
    findBuildingByQrCode({ commit }, payload) {
        return new Promise((resolve) => {
            checkInApi
                .getCheckInInitialData(payload.hash, payload.fromKiosk)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.PIN_IDENTIFICATION_STATUS, "found");
                        commit(types.PIN_IDENTIFICATION_DATA, response.data);
                        resolve();
                    } else {
                        commit(types.PIN_IDENTIFICATION_STATUS, "notFound");
                        resolve();
                    }
                })
                .catch(() => {
                    commit(types.PIN_IDENTIFICATION_STATUS, "notFound");
                    resolve();
                });
        });
    }
};

const mutations = {
    [types.PIN_IDENTIFICATION_STATUS](state, value) {
        state.status = value;
    },
    [types.PIN_IDENTIFICATION_DATA](state, value) {
        state.data = value;
        this.commit(types.SET_APP_FROM_KIOSK, value.fromKiosk);
        this.commit(types.SET_APP_CURRENT_BUILDING,
            value.fromKiosk && !value.checkOutTemperatureMeasuresEnabled ? value.qrCodeKey : null);
    }
};

const state = {
    status: "pending",
    data: {}
};

export default {
    state,
    getters,
    actions,
    mutations,
};