import * as types from "@/store/mutation-types";
import api from "@/services/api/adminFloors";
import {buildSuccess, convertDateTimes, handleError, handleErrorCode} from "@/utils/utils.js";

const getters = {
    floors: (state) => state.floors,
    totalFloor: (state) => state.totalFloor,
    floorStats: (state) => state.floorStats,
    totalFloorStats: (state) => state.totalFloorStats,
    uploadLayoutFileProgress: (state) => state.uploadLayoutFileProgress,
    floorLayout: (state) => state.floorLayout,
    userFloors: (state) => state.userFloors,
    
};

const actions = {
    getFloor({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getFloor(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FLOORS, response.data.docs);
                        commit(types.TOTAL_FLOORS, response.data.totalDocs);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    getUserFloors({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getUserFloors(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.USER_FLOORS, response.data);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doUpdateFloor({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .updateFloor(payload.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            handleErrorCode(response.data.errorCode, commit, reject);
                        } else {
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doCreateFloor({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .createFloor(payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            handleErrorCode(response.data.errorCode, commit, reject);
                        } else {
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    } else {
                        handleError(response, commit, reject);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doDeleteFloor({commit}, id) {
        return new Promise((resolve, reject) => {
            api
                .deleteFloor(id)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.DELETED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doUpdateFloorLayoutConfig({commit}, payload) {
 
        return new Promise((resolve, reject) => {
            api
                .updateFloorLayoutConfig(payload.id, payload.data)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            handleErrorCode(response.data.errorCode, commit, reject);
                        } else {
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doGetFloorLayout({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .getFloorLayout(payload)
            .then((response) => {
              if (response.status === 200) {
                commit(types.FLOOR_LAYOUT, response.data);
                resolve();
              }
            })
            .catch((error) => {
                commit(types.FLOOR_LAYOUT, {});
              handleError(error, commit, reject);
            });
        });
      },

      doUploadLayoutFile({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api
                .uploadLayoutFile(payload, (event) => {
                    const result = Math.round((100 * event.loaded) / event.total);
                    commit(types.LAYOUT_FILE_UPLOAD_PROGRESS, result);
                })
                .then((response) => {
               
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.SAVED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                        commit(types.LAYOUT_FILE_UPLOAD_PROGRESS, 0);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                    commit(types.LAYOUT_FILE_UPLOAD_PROGRESS, 0);
                });
        });
      },
      doDeleteLayoutFile({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api
            .getFloorLayout(payload)
            .then((response) => {
              if (response.status === 200) {
                commit(types.FLOOR_LAYOUT, response.data);
                resolve();
              }
            })
            .catch((error) => {
                commit(types.FLOOR_LAYOUT, {});
              handleError(error, commit, reject);
            });
        });
      },

   
};

const mutations = {
    [types.FLOORS](state, items) {
        convertDateTimes(items);
        state.floors = items;
    },
    [types.TOTAL_FLOORS](state, count) {
        state.totalFloor = count;
    },
    [types.FLOOR_STATS](state, items) {
        convertDateTimes(items);
        state.floorStats = items;
    },
    [types.TOTAL_FLOOR_STATS](state, count) {
        state.totalFloorStats = count;
    },
    [types.LAYOUT_FILE_UPLOAD_PROGRESS](state, result) {
        state.uploadLayoutFileProgress = result;
    },
    [types.FLOOR_LAYOUT](state, result) {
        state.floorLayout = result;
    },
    [types.USER_FLOORS](state, result) {
        state.userFloors = result;
    },
};

const state = {
    floors: [],
    totalFloor: 0,
    floorStats: [],
    totalFloorStats: 0,
    uploadLayoutFileProgress: 0,
    floorLayout: {},
    userFloors:[],


};

export default {
    state,
    getters,
    actions,
    mutations,
};
