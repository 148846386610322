import * as types from "@/store/mutation-types";
import packageJson from "../../../package.json";

const getters = {
  appTitle: (state) => state.appTitle,
  appVersion: (state) => state.appVersion,
  defaultAppBackground: (state) => state.defaultAppBackground,
  appBackground: (state) => state.appBackground,
  appWithoutBackground: (state) => state.appWithoutBackground,
  appHomeLink: (state) => state.appHomeLink,
  fromKiosk: (state) => state.fromKiosk,
  currentBuilding: (state) => state.currentBuilding
};

const actions = {
  setAppVersion({ commit }, payload) {
    commit(types.SET_APP_VERSION, payload);
  },
  setAppBackground({ commit }, payload) {
    commit(types.SET_APP_BACKGROUND, payload);
  }
};

const mutations = {
  [types.SET_APP_VERSION](state, version) {
    state.appVersion = version;
  },
  [types.SET_APP_BACKGROUND](state, backgroundClass) {
    state.appBackground = backgroundClass;
  },
  [types.SET_APP_HOMELINK](state, link) {
    state.appHomeLink = link;
  },
  [types.SET_APP_FROM_KIOSK](state, value) {
    state.fromKiosk = value;
  },
  [types.SET_APP_CURRENT_BUILDING](state, value) {
    state.currentBuilding = value;
  }
};

const state = {
  appTitle: "One Access",
  appVersion: packageJson.version,
  defaultAppBackground: "text-center bg",
  appBackground: "text-center bg", //this.defaultAppBackground,
  appWithoutBackground: "text-center",
  appHomeLink: {name: "home"},
  fromKiosk: false,
  currentBuilding: null
};

export default {
  state,
  getters,
  actions,
  mutations,
};
