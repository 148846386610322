import * as types from "@/store/mutation-types";
import api from "@/services/api/resetPassword";
import { buildSuccess, handleError } from "@/utils/utils.js";

const getters = {
  showChangePasswordInputs: (state) => state.showChangePasswordInputs,
};

const actions = {
  getUserForResetPassword({ commit }, token) {
    return new Promise((resolve, reject) => {
      api
        .getByResetPasswordToken(token)
        .then((response) => {
          if (response.status === 200) {
            commit(types.USER_TO_CHANGE_PASSWORD, response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            commit(types.SHOW_CHANGE_PASSWORD_INPUTS, false);
          }
          handleError(error, commit, reject);
        });
    });
  },
  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true);
      api
        .resetPassword(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.SHOW_CHANGE_PASSWORD_INPUTS, false);
            buildSuccess(
              {
                msg: "resetPassword.PASSWORD_CHANGED",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            commit(types.SHOW_CHANGE_PASSWORD_INPUTS, false);
          }
          handleError(error, commit, reject);
        });
    });
  },
  startResetFromAdminPanel({ commit }, userId) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true);
      api
        .adminResetPasswordStart(userId)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "resetPassword.ADMIN_RESET_STARTED",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
};

const mutations = {
  [types.SHOW_CHANGE_PASSWORD_INPUTS](state, value) {
    state.showChangePasswordInputs = value;
  },
  [types.USER_TO_CHANGE_PASSWORD](state, value) {
    state.userToChangePassword = value;
  },
};

const state = {
  showChangePasswordInputs: true,
  userToChangePassword: {},
};

export default {
  state,
  getters,
  actions,
  mutations,
};
