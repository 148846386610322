import axios from "axios";

export default {
  performStressTest(count, duration) {
    return axios.get(`/api/stresstest?count=${count}&duration=${duration}`);
  },
  actuatorHealth() {
    return axios.get(`/actuator/health`);
  },
};
