<template>
  <div v-if="item.address1">
    {{ item.address1 }} <br />
    <span v-if="item.address2">{{ item.address2 }} </span>
    {{ item.state + ", " + item.zip + ", " + item.city }}
  </div>
</template>

<script>
export default {
  name: "SimpleDisplayLocation",
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
