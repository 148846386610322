export default [
  {
    path: "/building-reports",
    name: "building-reports",
    meta: {
      requiresAuth: true,
      requiresTenant: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPERVISOR"],
    },
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/components/reports/BuildingReports.vue"
      ),
  },
  {
    path: "/visit-reports",
    name: "visit-reports",
    meta: {
      requiresAuth: true,
      requiresTenant: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPERVISOR"],
    },
    component: () =>
        import(
            /* webpackChunkName: "reports" */ "@/components/reports/VisitReports.vue"
            ),
  },
  {
    path: "/absence-reports",
    name: "absence-reports",
    meta: {
      requiresAuth: true,
      requiresTenant: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPERVISOR"],
    },
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/components/reports/AbsenceReports.vue"
        ),
  },
];
