import api from "@/services/api/adminTests";
import { buildSuccess, handleError } from "@/utils/utils.js";
import * as types from "@/store/mutation-types";

const getters = {
  health: (state) => state.health,
};

const actions = {
  performStressTest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .performStressTest(payload.count, payload.duration)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "adminTests.performing",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  actuatorHealth({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .actuatorHealth(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.HEALTH, response.data);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
};

const state = {
  health: {},
};
const mutations = {
  [types.HEALTH](state, health) {
    state.health = health;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
