export default [
  {
    path: "/checkin/:hash",
    name: "exposedCheckIn",
    meta: {
      hideToolbarItems: true,
      fromKiosk: false
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/exposedCheckIn/ExposedCheckIn.vue"
      ),
  },
  {
    path: "/checkin/:hash/:identifier",
    name: "checkInWizard",
    meta: {
      hideToolbarItems: true,
      fromKiosk: false
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/checkInWizard/CheckInWizard.vue"
      ),
  },
  {
    path: "/checkin/:hash/:identifier/:status",
    name: "checkInStatus",
    meta: {
      hideToolbarItems: true,
      fromKiosk: false
    },
    component: () =>
        import(
            /* webpackChunkName: "about" */ "@/components/checkInWizard/CheckInStatus.vue"
            ),
  }, {
    path: "/checkinKiosk/:hash",
    name: "exposedCheckInKiosk",
    meta: {
      hideToolbarItems: true,
      fromKiosk: true
    },
    component: () =>
        import(
            /* webpackChunkName: "about" */ "@/components/exposedCheckIn/ExposedCheckIn.vue"
            ),
  }, {
    path: "/checkinKiosk/:hash/:identifier",
    name: "checkInWizardKiosk",
    meta: {
      hideToolbarItems: true,
      fromKiosk: true
    },
    component: () =>
        import(
            /* webpackChunkName: "about" */ "@/components/checkInWizard/CheckInWizard.vue"
            ),
  }, {
    path: "/checkinKiosk/:hash/:identifier/:status",
    name: "checkInKioskStatus",
    meta: {
      hideToolbarItems: true,
      fromKiosk: true
    },
    component: () =>
        import(
            /* webpackChunkName: "about" */ "@/components/checkInWizard/CheckInStatus.vue"
            ),
  }, {
    path: "/registration/:hash",
    name: "exposedRegistration",
    meta: {
      hideToolbarItems: true,
      fromKiosk: false
    },
    component: () =>
      import(
        /* webpackChunkName: "exposedRegistration" */ "@/components/exposedRegistration/ExposedRegistration.vue"
      ),
  },
  {
    path: "/cancelVisit/:identifier",
    name: "cancelVisit",
    meta: {
      hideToolbarItems: true,
      fromKiosk: false
    },
    component: () =>
      import(
        /* webpackChunkName: "cancelVisit" */ "@/components/exposedCancelVisit/CancelVisit.vue"
      ),
  },
  {
    path: "/postponeCheckOut/:identifier",
    name: "postponeCheckOut",
    meta: {
      hideToolbarItems: true,
      fromKiosk: false
    },
    component: () =>
      import(
        /* webpackChunkName: "postponeCheckOut" */ "@/components/exposedPostponeCheckOut/PostponeCheckOut.vue"
      ),
  },
  {
    path: "/rescheduleVisit/:identifier",
    name: "rescheduleVisit",
    meta: {
      hideToolbarItems: true,
      fromKiosk: false
    },
    component: () =>
      import(
        /* webpackChunkName: "rescheduleVisit" */ "@/components/exposedReschedule/RescheduleVisit.vue"
      ),
  },
  {
    path: "/checkOut/:identifier",
    name: "exposedCheckOut",
    meta: {
      hideToolbarItems: true,
      fromKiosk: false
    },
    component: () =>
      import(
        /* webpackChunkName: "exposedCheckOut" */ "@/components/exposedCheckOut/ExposedCheckOut.vue"
      ),
  },
  {
    path: "/buildingCheckOut/:identifier",
    name: "buildingCheckOut",
    meta: {
      hideToolbarItems: true,
      fromKiosk: true
    },
    component: () =>
        import(
            /* webpackChunkName: "exposedCheckOut" */ "@/components/exposedCheckOut/BuildingCheckOut.vue"
            ),
  },
  {
    path: "/pinIdentification/:buildingHash",
    name: "pinIdentification",
    meta: {
      hideToolbarItems: true,
      fromKiosk: false
    },
    component: () =>
        import(
            /* webpackChunkName: "pinIdentification" */ "@/components/exposedPinIdentification/ExposedPinIdentification.vue"
            ),
  }, {
    path: "/employeeIdentification/:buildingHash",
    name: "employeeIdentification",
    meta: {
      hideToolbarItems: true,
      fromKiosk: false
    },
    component: () =>
        import(
            /* webpackChunkName: "pinIdentification" */ "@/components/exposedPinIdentification/EmployeePinIdentification.vue"
            ),
  }, {
    path: "/employeeIdentificationKiosk/:buildingHash",
    name: "employeeIdentificationKiosk",
    meta: {
      hideToolbarItems: true,
      fromKiosk: true
    },
    component: () =>
        import(
            /* webpackChunkName: "pinIdentification" */ "@/components/exposedPinIdentification/EmployeePinIdentification.vue"
            ),
  }
];
