<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex
          xs12
          v-for="(question, index) in questionnaire.items"
          :key="index"
      >
        <div v-if="! question.previousQuestionId ||
                                      inputVal[question.previousQuestionId] === question.previousQuestionAnswer">
          <div class="questionnaire-item questionnaire-item-color">
            <markdown :source="question.question" />
          </div>
          <ValidationProvider
              v-slot="{ errors }"
              rules="required|max:255">
            <v-select
                v-if="isQuestionSelectType(question.questionType)"
                v-model="inputVal[question.id]"
                @change="onQuestionAnswerChanged(question, index)"
                :items="questionAnswers(question.questionType, false)"
                item-text="label"
                item-value="value"
                clearable
                label=""
                :class="showWrongAnswers ? getBackgroundColor(question) : ''"
                :error="errors.length > 0"
                :error-messages="errors[0]"
            ></v-select>

            <v-text-field
                v-if="question.questionType === 'FREEFORM'"
                v-model="inputVal[question.id]"
                counter="255"
                :class="showWrongAnswers ? getBackgroundColor(question) : ''"
                @change="onQuestionAnswerChanged(question, index)"
                label=""
                :error="errors.length > 0"
                :error-messages="errors[0]"
            />

          </ValidationProvider>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {questionAnswers} from "@/components/common/questionnaires";

export default {
  name: "QuestionsDisplay",
  props: {
    value: {
      type: Object,
      default: null
    },
    questionnaire: {
      type: Object,
      default: null
    },
    showWrongAnswers: {
      type: Boolean,
      default: false
    },
    questionnairesStatuses: {
      type: Object,
      default: null
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    questionAnswers,
    isQuestionSelectType(type) {
      return type === 'YESNO' || type === 'YESNONA';
    },
    onQuestionAnswerChanged(currentQuestion, idx) {
      if(this.questionnaire.items[idx+1]) {
        const nextQuestion = this.questionnaire.items[idx+1];
        if (nextQuestion.previousQuestionId === currentQuestion.id && nextQuestion.previousQuestionAnswer !== this.inputVal[currentQuestion.id]) {
          delete this.inputVal[nextQuestion.id];
          this.onQuestionAnswerChanged(nextQuestion, ++idx);
        }
      }
    },
    getBackgroundColor(question) {
      if (this.questionnairesStatuses) {
        const qStatus = this.questionnairesStatuses[question.id];
        if (qStatus) {
          if ((question.questionType === 'YESNO' || question.questionType === 'YESNONA') && qStatus.alert) {
            return 'error--text row-with-alert';
          }
          if (question.questionType === 'YESNONA' && qStatus.warning) {
            return 'error--text row-with-alert';
          }
        }
      }
      return '';
    }
  },
}
</script>

<style scoped>

</style>