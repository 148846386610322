export function questionAnswers(type, withSkip = true) {
    let answers = [
        {
            label: this.$i18n.t("questionnaires.answers.SKIP"),
            value: "SKIP"
        },
        {
            label: this.$i18n.t("questionnaires.answers.NA"),
            value: "NA"
        },
        {
            label: this.$i18n.t("questionnaires.answers.YES"),
            value: "YES"
        },
        {
            label: this.$i18n.t("questionnaires.answers.NO"),
            value: "NO"
        }];
    if (type === 'YESNO') {
        answers.splice(1, 1);
    }
    if (! withSkip) {
        answers.splice(0, 1);
    }
    return answers;
}
export function getAnswerClass(question) {
    if ((question.type === 'YESNO' || question.type === 'YESNONA') && question.alert) {
        return 'alert-answer row-with-alert';
    }
    if (question.type === 'YESNONA' && question.warning) {
        return 'warning-answer row-with-warning';
    }
    return '';
}
export function formatAnswer(question) {
    if (question.type === 'YESNO' || question.type === 'YESNONA') {
        return this.$i18n.t('questionnaires.answers.' + question.answer);
    }
    return question.answer;
}
