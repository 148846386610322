import * as types from "@/store/mutation-types";
import api from "@/services/api/adminQuestionnaires";
import { buildSuccess, convertDateTimes, handleError } from "@/utils/utils.js";

const getters = {
  questionnaires: (state) => state.questionnaires,
  totalQuestionnaires: (state) => state.totalQuestionnaires,
  questions: (state) => state.questions,
};

const actions = {
  getQuestionnaires({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getQuestionnaires(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.QUESTIONNAIRES, response.data.docs);
            commit(types.TOTAL_QUESTIONNAIRES, response.data.totalDocs);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  doUpdateQuestionnaire({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateQuestionnaire(payload.id, payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "common.SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  doCreateQuestionnaire({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .createQuestionnaire(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: "common.SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  doDeleteQuestionnaire({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .deleteQuestionnaire(id)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "common.DELETED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  updateQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateQuestion(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "common.SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  createQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .createQuestion(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: "common.SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  doDeleteQuestion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteQuestion(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "common.DELETED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  doMoveQuestion({ commit }, payload) {
    // console.log("domove", payload);
    let item = payload.item;
    return new Promise((resolve, reject) => {
      api
        .moveQuestion(item.questionnaireId, item.id, payload.up)
        .then((response) => {
          if (response.status === 200) {
            commit(types.QUESTIONS, response.data);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  doGetQuestions({ commit }, questionnaireId) {
    return new Promise((resolve, reject) => {
      api
        .getQuestions(questionnaireId)
        .then((response) => {
          if (response.status === 200) {
            commit(types.QUESTIONS, response.data);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
};

const mutations = {
  [types.QUESTIONNAIRES](state, questionnaires) {
    convertDateTimes(questionnaires);
    state.questionnaires = questionnaires;
  },
  [types.TOTAL_QUESTIONNAIRES](state, questionnaires) {
    state.totalQuestionnaires = questionnaires;
  },
  [types.QUESTIONS](state, questions) {
    state.questions = questions;
  },
};

const state = {
  questionnaires: [],
  totalQuestionnaires: 0,
  questions: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
