import axios from "axios";

export default {
  getUsers(params) {
    return axios.get("/user", {
      params,
    });
  },
  editUser(id, payload) {
    return axios.patch(`/user/${id}`, payload);
  },
  saveUser(payload) {
    return axios.post("/user", payload);
  },
  deleteUser(id) {
    return axios.delete(`/user/${id}`);
  },
  changePasswordByAdmin(id, payload) {
    return axios.post(`/user/${id}/changePassword`, payload);
  },
  async countByEmail(email, userId) {
    const params =
      userId && userId !== "undefined" ? { email, userId } : { email };
    return axios.get("/validateUserEmail", {
      params,
    });
  },
  getUserBuildings(userId) {
    return axios.get(`/user/${userId}/buildings`);
  },
  updateUserBuilding(payload) {
    return axios.post(
      `/user/${payload.userId}/buildings/${payload.buildingId}/${payload.assign}`
    );
  },
  getUsersCsv(params) {
    const method = 'GET';
    const url = '/users/users.csv';
    axios.request({
      url,
      method,
      params,
      responseType: 'blob'
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'users.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }
};
