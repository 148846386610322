const readingsEnabled = process.env.VUE_APP_GEN_READINGS || false;

export default {
    methods: {
        displayTempScaleUnit(unit) {
            return unit === "CELSIUS" ? "°C" : "°F";
        },
        ruleForSecondMeasure(value, min, max) {
            return (this.disabledSecondMeasure(value, min, max) ? '' : 'required');
        },
        disabledSecondMeasure(value, min, max) {
            return (value === null || min <= value && max >= value);
        },
        genReadingsEnabled() {
            return readingsEnabled;
        },
        genReading() {
            return Math.round(Math.max(365, (Math.random() * (400 - 340) + 340))) / 10.0;
        }
    }
}