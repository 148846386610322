<template>
  <v-card-actions>
    <v-spacer />
    <v-btn class="mr-6" text v-if="currentStep > 1" @click="goBack">
      {{ $t("common.GO_BACK") }}
    </v-btn>
    <v-btn
      v-if="currentStep < stepsCount"
      color="primary"
      :disabled="nextDisabled"
      @click="goNext"
    >
      {{ $t("common.NEXT") }}
    </v-btn>
    <v-btn
      color="primary"
      v-if="currentStep === stepsCount"
      :disabled="(finishDisabled || finishClicked)"
      @click="finish"
    >
      {{ $t("common.FINISH") }}
    </v-btn>

    <v-btn class="ml-6" @click="cancel" text>
      {{ $t("common.CANCEL") }}
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name: "CheckInWizardButtons",
  data: function() {
    return {
      finishClicked: false
    };
  },
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
    stepsCount: {
      type: Number,
      default: 1,
    },
    finishDisabled: {
      type: Boolean,
      default: true,
    },
    nextDisabled: {
      type: Boolean,
      default: true,
    },
    readTemperature: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(flag) {
      if (flag) {
        this.finishClicked = false;
      }
    }
  },
  methods: {
    goBack() {
      this.$emit("on-go-back");
    },
    goNext() {
      this.$emit("on-go-next");
    },
    finish() {
      if (!this.finishClicked) {
        this.finishClicked = true;
        this.$emit("on-finish");
      }
    },
    cancel() {
      this.$emit("on-cancel");
    },
  }
};
</script>

<style scoped></style>
