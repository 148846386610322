import { render, staticRenderFns } from "./CommonWizard.vue?vue&type=template&id=d4b4b738&scoped=true"
import script from "./CommonWizard.js?vue&type=script&lang=js&external"
export * from "./CommonWizard.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4b4b738",
  null
  
)

export default component.exports