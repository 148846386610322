import * as types from "@/store/mutation-types";
import api from "@/services/api/exposed";
import router from "@/router";
import {convertDateTimes} from "@/utils/utils";

const getters = {
  checkInInitialData: (state) => state.initialData,
  checkInData: (state) => state.data,
  checkInStatus: (state) => state.status,
  checkInStatusDetails: (state) => state.statusDetails
};
const actions = {
  getCheckInInitialData({ commit }, payload) {
    return new Promise((resolve) => {
      api
        .getCheckInInitialData(payload.hash, payload.fromKiosk)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ECHECKIN_INITIAL_DATA, response.data);
            resolve();
          }
        })
        .catch((e) => {
          if (e.message && e.message.indexOf('Network Error') > -1) {
            commit(types.ERROR, "SERVER_CONNECTION_ERROR");
          } else {
            router.push({
              name: "about",
            });
          }
        });
    });
  },
  getCheckInData({ commit }, payload) {
    return new Promise((resolve) => {
      api
        .getCheckInData(payload.hash, payload.identifier, payload.fromKiosk)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ECHECKIN_DATA, response.data);
            resolve();
          } else if (response.status === 208) {
            commit(types.ERROR, "pinIdentification.CHECKEDIN");
          }
        })
        .catch((e) => {
          if (e.message && e.message.indexOf('404') > -1) {
            commit(types.ERROR, "CHECKIN_VISIT_NOT_FOUND");
          } else {
            commit(types.ERROR, "SERVER_CONNECTION_ERROR");
          }
        });
    });
  },
  getCheckInStatusDetails({ commit }, payload) {
    return new Promise((resolve) => {
      api
          .getCheckInStatusDetails(payload.hash, payload.identifier, payload.fromKiosk)
          .then((response) => {
            if (response.status === 200) {
              commit(types.ECHECKIN_STATUS_DETAILS, response.data);
              resolve();
            }
          })
          .catch((e) => {
              if (e.message && e.message.indexOf('404') > -1) {
                  commit(types.ERROR, "CHECKIN_VISIT_NOT_FOUND");
              } else {
                  commit(types.ERROR, "SERVER_CONNECTION_ERROR");
              }
          });
    });
  },
  exposedCheckIn({ commit }, payload) {
    return new Promise((resolve) => {
      api
        .checkIn(payload, payload.fromKiosk)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.checkIn) {
              commit(types.ECHECKIN_STATUS, {status: "success", identifier: data.meetingIdentifier});
            } else {
              commit(types.ECHECKIN_STATUS, {status: "error"});
            }
            resolve();
          }
        })
        .catch((e) => {
          if (e.message && e.message.indexOf('Network Error') > -1) {
            commit(types.ERROR, "SERVER_CONNECTION_ERROR");
          } else {
            commit(types.ECHECKIN_STATUS, {status: "error"});
            resolve();
          }
        });
    });
  },
};

const mutations = {
  [types.ECHECKIN_INITIAL_DATA](state, data) {
    convertDateTimes(data);
    state.initialData = data;
    state.data = {};
    this.commit(types.SET_APP_FROM_KIOSK, data.fromKiosk);
    this.commit(types.SET_APP_CURRENT_BUILDING,
        data.fromKiosk && !data.checkOutTemperatureMeasuresEnabled ? data.qrCodeKey : null);
  },
  [types.ECHECKIN_DATA](state, data) {
    state.data = data;
    if (data.fromKiosk !== undefined) {
      this.commit(types.SET_APP_FROM_KIOSK, data.fromKiosk);
      this.commit(types.SET_APP_CURRENT_BUILDING,
          data.fromKiosk && !data.checkOutTemperatureMeasuresEnabled ? data.qrCodeKey : null);
    }
  },
  [types.ECHECKIN_STATUS](state, data) {
    state.status = data.status;
    state.data.meetingIdentifier = data.identifier;
  },
  [types.ECHECKIN_STATUS_DETAILS](state, status) {
    convertDateTimes(status);
    state.statusDetails = status;
    if (status.fromKiosk !== undefined) {
      this.commit(types.SET_APP_FROM_KIOSK, status.fromKiosk);
      this.commit(types.SET_APP_CURRENT_BUILDING,
          status.fromKiosk && !status.checkOutTemperatureMeasuresEnabled ? status.qrCodeKey : null);
    }
  },
};
const state = {
  initialData: {},
  data: {},
  status: "pending",
  statusDetails: {}
};

export default {
  state,
  getters,
  actions,
  mutations,
};
