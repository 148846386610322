<template v-slot:activator="{ on }">
  <div>
    <v-app-bar flat dense :color="getAppBarColor">
      <span class="hidden-md-and-up">
        <v-app-bar-nav-icon color="white" @click="sidebar = !sidebar" />
      </span>
      <div class="headline text-uppercase ml-0">
        <div v-resize-text>
          <router-link :to="appHomeLink" style="cursor: pointer" class="site-logo" custom v-slot="{ navigate }">
            <span @click="navigate" @keypress.enter="navigate" role="link"></span>
          </router-link>
        </div>
      </div>
      <v-spacer />
      <v-toolbar-items v-if="showUserCheckOut">
        <v-btn text color="white" exact @click="userCheckout" class="hidden-sm-and-down">
          <v-icon>mdi-logout</v-icon>
          {{ $t("menuItems.CHECKOUT") }}
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-if="showToolbarItems && !currentBuilding">
        <template v-if="isTokenSet">
          <v-btn v-if="!isTech" text color="white" :to="{ name: 'checkinout' }" exact class="hidden-sm-and-down">
            <v-icon>mdi-login</v-icon>
            &nbsp;{{ $t("menuItems.ENTRY_LOG") }}
          </v-btn>
          <!--Add Space booking menu -->
          <v-menu v-if="!isAdmin" offset-y>
            <template #activator="{ on }">
              <v-btn text class="btnAdmin hidden-sm-and-down" color="white" v-on="on">
                <v-icon>mdi-map-marker</v-icon>
                &nbsp;{{ $t("menuItems.SPACES") }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{ name: 'spaceBookingCalendar' }" exact>
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-calendar-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t("menuItems.SPACES_CALENDAR") }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'spaceBookingMap' }" exact>
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-map-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t("menuItems.SPACES_BOOKING") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- Space booking menu end-->
          <v-menu v-if="isAdmin || isMaster || isSupervisor" offset-y>
            <template #activator="{ on }">
              <v-btn text class="btnAdmin hidden-sm-and-down" color="white" v-on="on">
                <v-icon>mdi-chart-box</v-icon>
                &nbsp;{{ $t("menuItems.REPORTS") }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{ name: 'building-reports' }" exact>
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-file-chart</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t("menuItems.BUILDING_REPORT") }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'visit-reports' }" exact>
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-file-chart</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t("menuItems.ENTRY_REPORT") }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  :to="{ name: 'absence-reports' }"
                  exact
              >
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-file-chart</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t("menuItems.ABSENCE_REPORT") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn text color="white" :to="{ name: 'profile' }" exact class="hidden-sm-and-down btnProfile">
            <v-icon>mdi-account-outline</v-icon>
            &nbsp;{{ $t("menuItems.MY_PROFILE") }}
          </v-btn>
        </template>
        <v-btn v-else text color="white" :to="{ name: 'about-ext' }" exact class="btnAbout">
          <v-icon>mdi-help-circle-outline</v-icon>
          &nbsp;{{ $t("menuItems.ABOUT") }}
        </v-btn>
        <v-menu v-if="isTokenSet && adminItems.length > 0" offset-y>
          <template #activator="{ on }">
            <v-btn text class="btnAdmin hidden-sm-and-down" color="white" v-on="on">
              <v-icon>mdi-lock</v-icon>
              &nbsp;{{ $t("adminItems.ADMIN") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in adminItems" :key="index" :to="{ name: item.link }" exact
              :class="[item.class]">
              <v-list-item-icon class="mr-2">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-list-item class="hidden-sm-and-down">
          <v-btn color="white" @click="isDark = false" small role="button" :aria-label="$t('common.themeSwitch.light')"
            elevation="0" class="theme-switch-left"><v-icon small>mdi-weather-sunny</v-icon></v-btn>
          <v-btn :color="getAppBarColor" @click="isDark = true" small role="button"
            :aria-label="$t('common.themeSwitch.dark')" elevation="0" class="theme-switch-right"><v-icon
              small>mdi-weather-night</v-icon></v-btn>
        </v-list-item>
        <v-tooltip bottom v-if="isTokenSet" :color="getAppBarColor">
          <template #activator="{ on }">
            <v-btn text color="white" v-on="on" @click="userLogout" exact>
              <v-icon>mdi-logout</v-icon>
              {{ $t("menuItems.LOGOUT") }}
            </v-btn>
          </template>
          {{ user.username }}
        </v-tooltip>
        <LocaleChanger />
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer v-model="sidebar" absolute disable-resize-watcher>
      <v-list>
        <v-list-item>
          <v-list-item-content>{{ appTitle }}</v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop="sidebar = !sidebar">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <template v-if="isTokenSet">
          <v-list-item :to="{ name: 'checkinout' }" exact>
            <v-list-item-action class="mr-1">
              <v-icon>mdi-login</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ $t("menuItems.ENTRY_LOG") }}</v-list-item-content>
          </v-list-item>
          <!--Add Spaces Menu start-->
          <v-list-group no-action v-if="!isAdmin">
            <v-list-item slot="activator" class="pl-0">
              <v-list-item-action class="mr-1"><v-icon>mdi-map-marker</v-icon></v-list-item-action>
              <v-list-item-content>
                {{ $t("menuItems.SPACES") }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'spaceBookingCalendar' }" exact class="pl-12">
              <v-list-item-action class="mr-1">
                <v-icon>mdi-calendar-clock-outline</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("menuItems.SPACES_CALENDAR") }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'spaceBookingMap' }" exact class="pl-12">
              <v-list-item-action class="mr-1">
                <v-icon>mdi-map-clock-outline</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("menuItems.SPACES_BOOKING") }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!--Add Spaces Menu end-->
          <v-list-group no-action>
            <v-list-item slot="activator" class="pl-0">
              <v-list-item-action class="mr-1"><v-icon>mdi-chart-box</v-icon></v-list-item-action>
              <v-list-item-content>
                {{ $t("menuItems.REPORTS") }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'building-reports' }" exact class="pl-12">
              <v-list-item-action class="mr-1">
                <v-icon>mdi-file-chart</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("menuItems.BUILDING_REPORT") }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'visit-reports' }" exact class="pl-12">
              <v-list-item-action class="mr-1">
                <v-icon>mdi-file-chart</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("menuItems.ENTRY_REPORT") }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="{ name: 'absence-reports'}"
                exact
                class="pl-12"
            >
              <v-list-item-action class="mr-1">
                <v-icon>mdi-file-chart</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("menuItems.ABSENCE_REPORT") }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="{ name: 'absence-reports'}"
                exact
                class="pl-12"
            >
              <v-list-item-action class="mr-1">
                <v-icon>mdi-file-chart</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t("menuItems.ABSENCE_REPORT") }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item :to="{ name: 'profile' }" exact>
            <v-list-item-action class="mr-1">
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ $t("menuItems.MY_PROFILE") }}</v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item v-else :to="{ name: 'about-ext' }" exact>
          <v-list-item-action class="mr-1">
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>{{ $t("menuItems.ABOUT") }}</v-list-item-content>
        </v-list-item>


        <v-list-group v-if="isAdmin || isTech || isMaster" no-action>
          <v-list-item slot="activator" class="pl-0">
            <v-list-item-action class="mr-1">
              <v-icon>mdi-lock</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              {{ $t("adminItems.ADMIN") }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="(item, index) in adminItems" :key="index" :to="{ name: item.link }" exact class="pl-12">
            <v-list-item-action class="mr-1">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item v-if="isTokenSet && !fromKiosk" @click="userLogout">
          <v-list-item-action class="mr-1">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t("menuItems.LOGOUT") }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showUserCheckOut" @click="userCheckout">
          <v-list-item-action class="mr-1">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t("menuItems.CHECKOUT") }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action class="ml-2">
            <v-switch id="themeSwitcher" v-model="isDark" inset color="info">
              <v-icon>mdi-weather-sunny</v-icon>
              <v-icon>mdi-weather-night</v-icon>
            </v-switch>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LocaleChanger from "@/components/core/LocaleChanger";
import ResizeText from "vue-resize-text";

export default {
  name: "Toolbar",
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { name: "msapplication-TileColor", content: "#ffc40d" },
        { name: "theme-color", content: "#ffffff" },
        {
          name: "apple-mobile-web-app-title",
          content: this.$store.getters.appTitle,
        },
        { name: "application-name", content: this.$store.getters.appTitle },
      ],
      link: [
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/apple-touch-icon.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/favicon-32x32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/favicon-16x16.png",
        },
        { rel: "manifest", href: "/site.webmanifest" },
        { rel: "mask-icon", color: "#5bbad5", href: "/safari-pinned-tab.svg" },
        { rel: "favicon", href: "/favicon.ico" },
      ],
    };
  },
  components: {
    LocaleChanger,
  },
  directives: {
    ResizeText,
  },
  data() {
    return {
      isDark: false,
      sidebar: false,
      showToolbarItems: true
    };
  },
  computed: {
    ...mapGetters(["appTitle", "appHomeLink", "fromKiosk", "currentBuilding", "isTokenSet", "isAdmin",
      "isTech", "isMaster", "isSupervisor", "user"]),
    getAppBarColor() {
      if (this.isAdmin) {
        return "toolbarAdmin";
      }
      if (this.isTech) {
        return "toolbarTech";
      }
      return "toolbar";
    },
    adminItems() {
      const tenants = {
        title: this.$t("adminItems.TENANTS"),
        link: "admin-tenants",
        icon: "mdi-briefcase",
        class: "btnAdminTenants",
      };
      const users = {
        title: this.$t("adminItems.USERS"),
        link: "admin-users",
        icon: "mdi-account-supervisor",
        class: "btnAdminUsers",
      };
      const people = {
        title: this.$t("adminItems.PEOPLE"),
        link: "admin-people",
        icon: "mdi-account-group",
        class: "btnAdminUsers",
      };
      const buildings = {
        title: this.$t("adminItems.BUILDINGS"),
        link: "admin-buildings",
        icon: "mdi-office-building-marker-outline",
        class: "btnAdminBuildings",
      };
      const questionnaires = {
        title: this.$t("adminItems.QUESTIONNAIRES"),
        link: "admin-questionnaires",
        icon: "mdi-text-box",
        class: "btnAdminQuestionnaires",
      };
      const tests = {
        title: this.$t("adminItems.TESTS"),
        link: "admin-tests",
        icon: "mdi-file-find",
        class: "btnAdminTests",
      };
      const logs = {
        title: this.$t("adminItems.LOGS"),
        link: "admin-logs",
        icon: "mdi-playlist-check",
        class: "btnAdminLogs",
      };
      const floors = {
        title: this.$t("adminItems.FLOORS"),
        link: "admin-floors",
        icon: "mdi-layers-outline",
        class: "btnAdminLogs",
      };
      if (this.isAdmin) {
        return [tenants, users, people, buildings, floors, questionnaires, tests, logs];
      } else if (this.isMaster) {
        return [users, people, buildings, floors, questionnaires];
      } else if (this.isSupervisor) {
        return [users, people, buildings, floors];
      } else if (this.isTech) {
        return [tenants, users];
      } else {
        return [];
      }
    },
    mobileMenuItems() {
      const array = [...this.menuItems];
      array.push();
      return array;
    },
    menuItems() {
      if (this.isTokenSet) {
        const checkInOut = {
          title: this.$t("menuItems.ENTRY_LOG"),
          link: "checkinout",
          icon: "mdi-login",
        };
        const reports = {
          title: this.$t("menuItems.REPORTS"),
          icon: "mdi-chart-box-outline",
          link: "reports",
        };
        
        const profile = {
          title: this.$t("menuItems.MY_PROFILE"),
          link: "profile",
          icon: "mdi-account-outline",
          class: "btnProfile",
        };
        if (this.isAdmin || this.isMaster || this.isSupervisor) {
          return [checkInOut, reports, profile];
          // return [checkInOut, booking, reports, profile];
        } else if (this.isTech) {
          return [profile];
        }
        else {
          return [checkInOut, profile];
        }
      }
      return [
        {
          title: this.$t("menuItems.ABOUT"),
          link: "about-ext",
          icon: "mdi-help-circle-outline",
          class: "btnAbout",
        }
      ];
    },
    showUserCheckOut() {
      return false;
    }
  },
  watch: {
    isDark() {
      this.$vuetify.theme.dark = this.isDark;
      localStorage.setItem("dark", this.isDark);
    },
    $route(to) {
      this.showToolbarItems = !(to && to.meta && to.meta.hideToolbarItems);
    }
  },
  created() {
    const dark = localStorage.getItem("dark");
    this.isDark = dark ? JSON.parse(dark) : false;
  },
  methods: {
    userLogout() {
      this.$store.dispatch("userLogout");
    },
    userCheckout() {
      let path = '/buildingCheckOut/' + this.currentBuilding;
      if (path !== this.$route.path) {
        this.$router.push(path);
      }
    }
  },
};
</script>

<style>
.dialog-header {
  padding: 0.5rem 0.5rem 0.5rem 2.5rem !important;
  background-size: 2rem;
  background-position: 0 center;
  display: block;
  border-bottom: 1px solid #999;
  margin-left: 1rem;
  margin-right: 1rem;
}

.theme--light .dialog-header {
  background-image: url("./OneAccessLogoDark.png");
  color: #4D5AA3;
}

.theme--dark .dialog-header {
  background-image: url("./OneAccessLogoLight.png");
  color: rgba(255, 255, 255, 0.7);
}
</style>
<style scoped>
/*.v-btn,*/
/*.v-icon {*/
/*  font-size: 0.875rem;*/
/*}*/
.v-btn--text .v-icon {
  margin-right: 0.3rem;
}

.site-logo {
  height: 1rem;
  width: 1.9rem;
  background-position: left center;
  background-size: cover;
  display: inline-block;
  margin-right: 1rem;
  margin-top: 0.7rem;
  background-image: url("./OneAccessLogo.png");
}

@media screen and (min-width: 1150px) {
  .site-logo {
    width: 10rem;
    background-size: contain;
  }
}

.theme-switch-left,
.theme-switch-right {
  opacity: 1 !important;
  min-width: 1rem !important;
  max-width: 2rem;
  border-radius: 0;
}

.theme-switch-left {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.toolbar .theme-switch-right {
  border-left: 0 !important;
}

.toolbar .theme-switch-left {
  border-right: 0 !important;
}

.theme-switch-right {
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
}

.theme--light .theme-switch-right,
.theme--dark .theme-switch-left {
  background-color: var(--v-toolbar-base) !important;
  border: 2px solid #ffffff !important;
}

.theme--light.toolbar .theme-switch-right,
.theme--dark.toolbar .theme-switch-left {
  background-color: var(--v-toolbar-base) !important;
}

.theme--light.toolbarTech .theme-switch-right,
.theme--dark.toolbarTech .theme-switch-left {
  background-color: var(--v-toolbarTech-base) !important;
}

.theme--light.toolbarAdmin .theme-switch-right,
.theme--dark.toolbarAdmin .theme-switch-left {
  background-color: var(--v-toolbarAdmin-base) !important;
}

.theme--light .theme-switch-right i {
  color: #ffffff;
}

.theme--light .theme-switch-left,
.theme--dark .theme-switch-right {
  background-color: #ffffff !important;
}

.theme--dark .theme-switch-right {
  border: 2px solid #ffffff !important;
}

.theme--light.toolbar .theme-switch-left,
.theme--dark.toolbar .theme-switch-right {
  color: var(--v-toolbar-base) !important;
}

.theme--light.toolbarTech .theme-switch-left,
.theme--dark.toolbarTech .theme-switch-right {
  color: var(--v-toolbarTech-base) !important;
}

.theme--light.toolbarAdmin .theme-switch-left,
.theme--dark.toolbarAdmin .theme-switch-right {
  color: var(--v-toolbarAdmin-base) !important;
}
</style>
