import Vue from "vue";
import "@/plugins/axios";
import vuetify from "@/plugins/vuetify";
import "@/plugins/veevalidate";
import "@/plugins/common";
import i18n from "@/plugins/i18n";
import App from "@/App.vue";
import router from "@/router";
import {store} from "@/store";
import VuetifyConfirm from "vuetify-confirm";
import DatetimePicker from "vuetify-datetime-picker";
import HighchartsVue from 'highcharts-vue'
import {availableLanguageCodes} from '@/components/common/commons.js';

Vue.config.productionTip = false;
Vue.use(VuetifyConfirm, {vuetify});
Vue.use(DatetimePicker);
Vue.use(HighchartsVue);

const app = new Vue({
    vuetify,
    router,
    store,
    i18n,
    created() {
        let lang = store.getters.locale;
        if (availableLanguageCodes.filter(loc => loc.lang === lang).length === 0) {
            lang = availableLanguageCodes[0];
        }
        store.dispatch("setLocale", lang);
        if (store.getters.isTokenSet) {
            store.dispatch("autoLogin");
        }
    },
    render: (h) => h(App),
}).$mount("#app");

if (window.Cypress) {
    // Only available during E2E tests
    window.app = app;
}
