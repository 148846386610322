export default [
  {
    path: "/about",
    name: "about",
    meta: {
      hideToolbarItems: true
    },
    beforeEnter() {
      window.location.href = "https://www.oneaccess.com"
    }
  },
  {
    path: "/about",
    name: "about-ext",
    meta: {
      hideToolbarItems: true
    },
    beforeEnter() {
      window.open("https://www.oneaccess.com", "_blank");
    }
  },
];
