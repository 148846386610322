import axios from "axios";

export default {
    getTemperatureInfo(id) {
        return axios.get(`/camera/info/${id}`);
    },
    createCamera(id, payload) {
        return axios.post(`/camera/${id}`, payload);
    },
    updateCamera(id, payload) {
        return axios.patch(`/camera/${id}`, payload);
    },
    deleteCamera(id) {
        return axios.delete(`/camera/${id}`);
    },
    findCamerasByBuilding(id) {
        return axios.get(`/camera/${id}`);
    }
};
