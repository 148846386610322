<template>
  <v-menu v-model="showMenu" offset-y>
    <template #activator="{ on }">
      <v-btn color="white" class="btnLocaleActivation" text v-on="on">
        <v-icon>mdi-earth</v-icon>
        &nbsp;{{ displayLocale }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, i) in availableLanguages"
        :key="`Lang${i}`"
        active-class="white--text"
        :value="item.lang"
        :class="[item.class]"
        @click="switchLocale(item)"
      >
        <country-flag :country="item.flag" size="small" />
        <v-list-item-title class="ml-3">
          {{ item.lang.toUpperCase() }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import CountryFlag from "vue-country-flag";
import { mapActions, mapGetters } from "vuex";
import { availableLanguages } from "@/components/common/commons";

export default {
  components: {
    CountryFlag,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    displayLocale() {
      return this.$i18n.locale;
    },
    availableLanguages
  },
  methods: {
    ...mapActions(["setLocale", "addProfileData", "saveProfile"]),
    switchLocale(item) {
      this.setLocale(item.lang);
      const data = {
        key: "userLocale",
        value: item.lang,
      };
      this.addProfileData(data);
      //console.log("localechanger", this.user);
      if (this.user) {
        this.saveProfile({
          userLocale: item.lang,
        });
      }
    },
  },
};
</script>

<style scoped>
.flag {
  width: 100px !important;
}
.v-list-item {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}
.v-btn .v-icon {
  margin-right: 0.3rem;
}
</style>
