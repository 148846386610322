export const allVisitorTypes = function () {
  return [
    {
      label: this.$t("checkInOut.VISITOR_TYPES.VISITOR"),
      value: "VISITOR",
    },
    {
      label: this.$t("checkInOut.VISITOR_TYPES.EMPLOYEE"),
      value: "EMPLOYEE",
    },
    {
      label: this.$t("checkInOut.VISITOR_TYPES.CONTRACTOR"),
      value: "CONTRACTOR",
    },
    {
      label: this.$t("checkInOut.VISITOR_TYPES.RESIDENT"),
      value: "RESIDENT",
    },
  ];
};
export const allVisitorStatuses = function () {
  return [
    { label: this.$t("checkInOut.VISITOR_STATUS.IN"), value: "IN" },
    { label: this.$t("checkInOut.VISITOR_STATUS.OUT"), value: "OUT" },
    { label: this.$t("checkInOut.VISITOR_STATUS.LATE"), value: "LATE" },
    { label: this.$t("checkInOut.VISITOR_STATUS.SOON"), value: "SOON" },
    { label: this.$t("checkInOut.VISITOR_STATUS.TODAY"), value: "TODAY" },
    { label: this.$t("checkInOut.VISITOR_STATUS.EXPECTED"), value: "EXPECTED" },
    { label: this.$t("checkInOut.VISITOR_STATUS.EXPIRED"), value: "EXPIRED" },
  ];
};
export const allFilterOnValues = function () {
  return [
    { label: this.$t("checkInOut.FILTER_ON.CHECK_IN"), value: "checkInAt" },
    {
      label: this.$t("checkInOut.FILTER_ON.CHECK_OUT"),
      value: "checkOutAt",
    },
    {
      label: this.$t("checkInOut.FILTER_ON.SCHEDULED_DATE"),
      value: "scheduledAt",
    },
  ];
};
export const resolveRule = function (fieldName, field) {
  const val = [];
  if (field.mandatory) {
    val.push("required");
  }
  if (fieldName === "email") {
    val.push("email");
  }
  if (field.type === "TEXT") {
    val.push("max:" + field.size);
  }
  return val.join("|");
};
