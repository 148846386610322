import * as types from "@/store/mutation-types";
import api from "@/services/api/adminSpaces";
import {buildSuccess, convertDateTimes, handleError, handleErrorCode} from "@/utils/utils.js";
/* eslint-disable */
const getters = {
    spaces: (state) => state.spaces,
    totalSpace: (state) => state.totalSpace,
    spaceStats: (state) => state.spaceStats,
    totalSpaceStats: (state) => state.totalSpaceStats,
    space:(state)=>state.space, 
    spaceApprover:(state) => state.spaceApprover,
    spaceList:(state) => state.spaceList,
};

const actions = {
    getSpace({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getSpace(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.SPACES, response.data.docs);
                        commit(types.TOTAL_SPACES, response.data.totalDocs);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    getSpaceList({commit}, payload) {

        return new Promise((resolve, reject) => {
            api
                .getSpaceList(payload)
                .then((response) => {
                    if (response.data && response.data.errorCode) {
                        commit(types.SPACE_LIST, []);
                        //handleErrorCode(response.data.errorCode, commit, reject);
                    } else  {
                        commit(types.SPACE_LIST, response.data);
                        resolve();
                    }
                })
                .catch((error) => {
                    commit(types.SPACE_LIST, []);
                    handleError(error, commit, reject);
                });
        });
    },
    getSpaceByFloorId({commit}, payload) {

        return new Promise((resolve, reject) => {
            api
                .getSpaceByFloorId(payload.id, payload.params)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.SPACES, response.data.docs);
                        commit(types.TOTAL_SPACES, response.data.totalDocs);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    getSpaceApprover({commit}, buildingId) {
    

        return new Promise((resolve, reject) => {
            api
                .getSpaceApprover(buildingId)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.SPACE_APPROVER, response.data);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doUpdateSpace({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .updateSpace(payload.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            handleErrorCode(response.data.errorCode, commit, reject);
                        } else {
                            commit(types.SPACES, response.data.docs);
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doCreateSpace({commit}, payload) {
  
        return new Promise((resolve, reject) => {
            api
                .createSpace(payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            handleErrorCode(response.data.errorCode, commit, reject);
                        } else {
                           
                            commit(types.SPACE, response.data);
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    } else {
                        handleError(response, commit, reject);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doDeleteSpace({commit}, id) {
        return new Promise((resolve, reject) => {
            api
                .deleteSpace(id)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.DELETED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
   

};

const mutations = {
    [types.SPACES](state, items) {
        convertDateTimes(items);
        state.spaces = items;
    },
    [types.TOTAL_SPACES](state, count) {
        state.totalSpace = count;
    },
    [types.SPACE_STATS](state, items) {
        convertDateTimes(items);
        state.spaceStats = items;
    },
    [types.TOTAL_SPACE_STATS](state, count) {
        state.totalSpaceStats = count;
    },
    [types.SPACE](state, item) {
        state.space = item;
    },
    [types.SPACE_APPROVER](state, item) {
        state.spaceApprover = item;
    },[types.SPACE_LIST](state, item) {
        state.spaceList = item;
    },
   
};

const state = {
    spaces: [],
    totalSpace: 0,
    spaceStats: [],
    totalSpaceStats: 0,
    space: {},
    spaceApprover:[],
    spaceList:[],

};

export default {
    state,
    getters,
    actions,
    mutations,
};
