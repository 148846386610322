<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px" content-class="dlgNewEditItem">
      <v-card>
        <v-card-title class="dialog-header">{{ formTitle }}</v-card-title>

        <ValidationObserver
            ref="observer"
            tag="form"
            @submit.prevent="submit()"
        >
          <v-card-text class="pt-0">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <div style="padding:0.5rem;position:relative;">
                    <v-overlay opacity="0" :absolute="true" :value="loadingPicture" v-if="editedItem.id && loadingPicture">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-overlay>
                    <v-layout v-if="noPicture && editedItem.id" class="camera-info-admin">{{ $t("temperatureForm.noPicture")}}</v-layout>
                    <v-img v-if="!noPicture && editedItem.id" contain :src="pictureUrl" @error="failedLoadingPicture" @load="showLoadedPicture"
                           @click="updatePictureUrl" class="camera-picture-admin"></v-img>
                  </div>
                  <template v-for="(field, index) in cameraFields">
                    <ValidationProvider v-slot="{ errors }" :rules="field[1]" :key="index">
                      <v-text-field
                          :id="field[0]"
                          v-model="editedItem[field[0]]"
                          :class="field[2]"
                          :name="field"
                          :label="$t('camera.field.' + field[0])"
                          :error="errors.length > 0"
                          :error-messages="errors[0]"
                          autocomplete="off"
                      />
                    </ValidationProvider>
                  </template>
                  <v-select
                      name="cameraPlace"
                      class="required"
                      v-model="editedItem.cameraPlace"
                      :label="$t('camera.field.place')"
                      :items="cameraPlaces" />
                  <v-checkbox
                      v-model="editedItem.active"
                      name="isActive"
                      :label="$t('camera.field.active')"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="red lighten3" text class="btnCancel" @click="close">
              {{ $t("dataTable.CANCEL") }}
            </v-btn>
            <v-btn color="green lighten3" text class="btnSave" @click="submit">
              {{ $t("dataTable.SAVE") }}
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "EditCamera",
  data: function () {
    return {
      dialog: false,
      saving: false,
      editedItem: {},
      lastPicture: 0,
      pictureUrl: '',
      loadingPicture: false,
      noPicture: false
    };
  },
  computed: {
    formTitle() {
      return this.editedItem.id
          ? this.$t("camera.title.edit")
          : this.$t("camera.title.add");
    },
    cameraFields() {
      return [['name', 'required|max:128', 'required'], ['ipAddress', 'required|max:128', 'required'],
        ['authUser', 'max:128', ''], ['authPassword', 'max:128', '']];
    },
    cameraPlaces() {
      return [
        {"value": "ENTRY", "text": this.$t("camera.places.ENTRY")},
        {"value": "EXIT", "text": this.$t("camera.places.EXIT")},
        {"value": "ENTRY_EXIT", "text": this.$t("camera.places.ENTRY_EXIT")}
      ];
    },
  },
  methods: {
    ...mapActions([
      "createCamera",
      "updateCamera"
    ]),
    open(camera) {
      this.dialog = true;
      this.saving = false;
      this.editedItem = camera;
      this.updatePictureUrl();
    },
    updatePictureUrl() {
      console.log('updatepictureurl');
      if (this.editedItem.id) {
        this.loadingPicture = true;
        this.lastPicture = (new Date()).getTime();
        // return 'https://' + this.editedItem.authUser + ':' + this.editedItem.authPassword + '@' +
        this.pictureUrl = (process.env.VUE_APP_API_BASE_URL || "") + '/camera/picture/' + this.editedItem.id + '?x=' + this.lastPicture;
      }
    },
    showLoadedPicture() {
      console.log('showLoadedPicture');
      this.noPicture = false;
      this.loadingPicture = false;
    },
    failedLoadingPicture() {
      console.log('failedLoadingPicture');
      this.noPicture = true;
      this.loadingPicture = false;
    },
    reset() {
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    close() {
      this.dialog = false;
      this.reset();
    },
    submit() {
      if (!this.saving) {
        this.saving = true;
        this.save();
      }
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          if (this.editedItem.id) {
            await this.updateCamera(this.editedItem);
          } else {
            await this.createCamera(this.editedItem);
          }
          this.close();
          // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.close();
        }
      } else {
        this.saving = false;
      }
    },
  }
}
</script>

<style scoped>

</style>