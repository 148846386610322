import i18n from "@/plugins/i18n";
import * as types from "@/store/mutation-types";
import { isPast, format, parseISO } from "date-fns";
import { store } from "@/store";
import { es, zhCN } from "date-fns/locale";

const localesDateFns = {
  es,
  cn: zhCN,
};

export const PERSON_SEARCH_MIN_CHARS = 2;

export const getFormat = (date, skipSeconds) => {
  if (date) {
    window.__localeId__ = store.getters.locale;
    const formatDateTime =
      store.getters.userFormatDateTime || "yyyy-MM-dd HH:mm";
    const finalFormat = skipSeconds
      ? formatDateTime.replace(":ss", "")
      : formatDateTime;
    return format(date, finalFormat, {
      locale: localesDateFns[window.__localeId__],
    });
  }
};

export const getFormatDate = (date) => {
  if (date) {
    window.__localeId__ = store.getters.locale;
    const formatDate = store.getters.userDateFormat || "yyyy-MM-dd";
    return format(date, formatDate, {
      locale: localesDateFns[window.__localeId__],
    });
  }
};

export const getFormatTime = (date) => {
  if (date) {
    window.__localeId__ = store.getters.locale;
    const formatDate = store.getters.userTimeFormat || "HH:mm";
    return format(date, formatDate, {
      locale: localesDateFns[window.__localeId__],
    });
  }
};

export const formatErrorMessages = (translationParent, msg) => {
  const errorArray = [];
  // Check for error msgs
  if (msg !== null) {
    const json = JSON.parse(JSON.stringify(msg));
    // If error message is an array, then we have mutiple errors
    if (Array.isArray(json)) {
      json.map((error) => {
        let values = [];
        if (error.params) {
          values = [...error.params];
        }
        errorArray.push(i18n.t(`${translationParent}.${error.msg}`, values));
      });
    } else {
      // Single error
      errorArray.push(i18n.t(`${translationParent}.${msg}`));
    }
    return errorArray;
  }
  // all good, return null
  return null;
};

export const buildPayloadPagination = (pagination, search) => {
  const { page, itemsPerPage } = pagination;
  let { sortDesc, sortBy } = pagination;

  // When sorting you always get both values
  if (sortBy.length === 1 && sortDesc.length === 1) {
    // Gets order
    sortDesc = sortDesc[0] === true ? -1 : 1;
    // Gets column to sort on
    sortBy = sortBy ? sortBy[0] : "";
  } else {
    sortDesc = 0;
  }

  let query = {
    sort: sortBy,
    order: sortDesc,
    page,
    limit: itemsPerPage,
    ...pagination.searchCriterias,
  };

  // If search and fields are defined
  if (search) {
    query.filter = search.query;
    query.fields = search.fields;
  }
  return query;
};

export const handleSimpleError = (error) => {
  if (error.response.status === 401 || error.response.status === 403) {
    try {
      store.commit(types.SHOW_LOADING, false);
      store.commit(types.ERROR, null);
      store.dispatch("userLogout");
    } catch (e) {
      console.log(e);
    }
  }
};
export const handleErrorCode = (errorCode, commit, reject) => {
  handleError({
    "response": {
      "status": false,
      "data": {
        "msg": errorCode
      }
    }
  }, commit, reject);
}
// Catches error connection or any other error (checks if error.response exists)
export const handleError = (error, commit, reject) => {
  let errMsg = "";
  // Resets errors in store
  commit(types.SHOW_LOADING, false);
  commit(types.ERROR, null);

  // Checks if unauthorized
  if (error.response.status === 401 || error.response.status === 403) {
    store.dispatch("userLogout");
  } else {
    // Any other error
    errMsg =
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg
        : "SERVER_TIMEOUT_CONNECTION_ERROR";
    setTimeout(() => {
      return errMsg
        ? commit(types.ERROR, errMsg)
        : commit(types.SHOW_ERROR, false);
    }, 0);
  }
  reject(error);
};

export const buildSuccess = (
  msg,
  commit,
  resolve,
  resolveParam = undefined
) => {
  commit(types.SHOW_LOADING, false);
  commit(types.SUCCESS, null);
  setTimeout(() => {
    return msg ? commit(types.SUCCESS, msg) : commit(types.SHOW_SUCCESS, false);
  }, 0);
  commit(types.ERROR, null);
  resolve(resolveParam);
};

// Checks if tokenExpiration in localstorage date is past, if so then trigger an update
export const checkIfTokenNeedsRefresh = () => {
  // Checks if time set in localstorage is past to check for refresh token
  if (
    window.localStorage.getItem("token") !== null &&
    window.localStorage.getItem("tokenExpiration") !== null
  ) {
    if (
      isPast(
        new Date(
          parseISO(JSON.parse(window.localStorage.getItem("tokenExpiration"))) *
            1000
        )
      )
    ) {
      store.dispatch("refreshToken");
    }
  }
};

export const convertDateTimes = (obj) => {
  try {
    if (obj) {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (typeof value === "string" && isDateTime(value)) {
          obj[key] = parseISO(formatDateTime(value));
        } else if (typeof value === "object") {
          // eslint-disable-next-line no-unused-vars
          convertDateTimes(value);
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const resetDateTimePicker = function (refs, name) {
  if (refs[name]) {
    refs[name].date = "";
    refs[name].time = "00:00:00";
  } else {
    setTimeout(function () {
      if (refs[name]) {
        refs[name].date = "";
        refs[name].time = "00:00:00";
      }
    }, 100);
  }
};

export const UPLOAD_PARAMS = "uploadParams";

function isDateTime(prop) {
  return (
    prop.length >= 19 &&
    prop.length <= 26 &&
    prop.match(/^\d/) &&
    !isNaN(new Date(prop).getTime())
  );
}

function formatDateTime(dateStr) {
  if (dateStr) {
    const prefix =
      dateStr.indexOf(".") === -1 ? dateStr : dateStr.split(".")[0];
    return prefix + ".000Z";
  } else {
    return undefined;
  }
}

export const formatISODateTime = function(d) {
  if (d) {
    let months = d.getMonth() + 1;
    let days = d.getDate();
    let hours = d.getHours();
    let minutes = d.getMinutes();
    return d.getFullYear() + '-' + (months < 10 ? '0' + months : months) + '-' + (days < 10 ? '0' + days : days) +
      'T' + (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':00.000Z';
  }
  return '';
}
