import Vue from "vue";
import Heading from "@/components/common/Heading.vue";
import Description from "@/components/common/Description.vue";
import ErrorMessage from "@/components/common/ErrorMessage.vue";
import SuccessMessage from "@/components/common/SuccessMessage.vue";
import SubmitButton from "@/components/common/SubmitButton.vue";
import PhoneInput from "@/components/common/phone/PhoneInput.vue";
import formatdatetimes from "@/mixins/formatdatetimes";
import formatphonenumbers from "@/mixins/formatphonenumbers";
import EditBuilding from "@/components/editBuilding/EditBuilding.vue";
import CardList from "@/components/common/CardList";
import ZipInput from "@/components/common/zip/ZipInput";
import WarningMessage from "@/components/common/WarningMessage";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import checkin from "@/mixins/checkin";
import temperatures from "@/mixins/temperatures";
import SimpleDisplayLocation from "@/components/common/SimpleDisplayLocation";
import CommonWizard from "@/components/common/wizard/CommonWizard.vue";
import Markdown from "@/components/common/Markdown";
import QuestionsDisplay from "@/components/common/questionnaires/QuestionsDisplay";
import CheckInWizardButtons from "@/components/checkInWizard/wizardButtons/CheckInWizardButtons";
import DurationInput from "@/components/common/duration/DurationInput";
import EditCamera from "@/components/editBuilding/EditCamera";
import TemperatureForm from "@/components/temperature/TemperatureForm";
import ICS from "@/components/common/ics.js";

Vue.component("Heading", Heading);
Vue.component("Description", Description);
Vue.component("ErrorMessage", ErrorMessage);
Vue.component("SuccessMessage", SuccessMessage);
Vue.component("WarningMessage", WarningMessage);
Vue.component("SubmitButton", SubmitButton);
Vue.component("PhoneInput", PhoneInput);
Vue.component("DurationInput", DurationInput);
Vue.component("EditBuilding", EditBuilding);
Vue.component("EditCamera", EditCamera);
Vue.component("qrcode", VueQrcode);
Vue.component("CardList", CardList);
Vue.component("ZipInput", ZipInput);
Vue.component("SimpleDisplayLocation", SimpleDisplayLocation);
Vue.component("CommonWizard", CommonWizard)
Vue.component("Markdown", Markdown);
Vue.component("QuestionsDisplay", QuestionsDisplay)
Vue.component("CheckInWizardButtons", CheckInWizardButtons)
Vue.component("TemperatureForm", TemperatureForm)

Vue.mixin(formatdatetimes);
Vue.mixin(checkin);
Vue.mixin(formatphonenumbers);
Vue.mixin(temperatures);

Vue.use(ICS, { prodId: 'OneAccess', uidDomain: 'www.oneaccess.com' });