import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/src/styles/main.sass";
// import colors from 'vuetify/es5/util/colors'
// import VuetifyConfirm from 'vuetify-confirm'
import "@mdi/font/css/materialdesignicons.css";
import "@/styles/global.css";

Vue.use(Vuetify);

const theme = JSON.parse(localStorage.getItem("dark")) || false;
const opts = {
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        primary: "#4d5aa3", //"#1976D2",
        toolbar: "#4d5aa3",
        toolbarAdmin: "#e95001",
        toolbarTech: "#126c00",
        secondary: "#4d5aa3", //#424242
        success: "#4CAF50",
        warning: "#FB8C00",
        title: "#4d5aa3",
        bg: "#f5f6f8",
        input: "#4d5aa3",
        bgTable: "#ffffff",
        tableBorder: "#e0e0e0",
        highlighted: "#fafafa"
      },
      dark: {
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        primary: "#babcc8", //"#2196F3",
        toolbar: "#283269", //toolbar switch
        toolbarAdmin: "#ab3c02", //toolbar switch
        toolbarTech: "#274d00", //toolbar switch
        secondary: "#283269",
        success: "#4CAF50",
        warning: "#FB8C00",
        title: "#efdede",
        bg: "#2d2d2d",
        input: "#babcc8",
        bgTable: "#1c1b1b",
        tableBorder: "#ffffff",
        highlighted: "#fafafa"
      },
    },
    dark: theme !== false,
  },
  iconfont: "mdi",
};

export default new Vuetify(opts);
