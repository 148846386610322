import axios from "axios";

export default {
  getMeetings(params) {
    return axios.get("/meeting", {
      params,
    });
  },
  getMeetingsStats(buildingId, params) {
    return axios.get(`meetingStats/${buildingId}`, {
      params
    })
  },
  getMeetingsCsv(params) {
    const method = 'GET';
    const url = '/meetings/meetings.csv';
    axios.request({
      url,
      method,
      params,
      responseType: 'blob'
    })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'meetings.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
  },
  saveMeeting(payload) {
    return axios.post("/meeting", payload);
  },
  saveAndCheckInMeeting(payload) {
    return axios.post("/meetingWithCheckIn", payload);
  },
  editMeeting(id, payload) {
    return axios.patch(`/meeting/${id}`, payload);
  },
  editMeetingQuestionnaires(id, payload) {
    return axios.patch(`/meeting/${id}/questionnaires`, payload);
  },
  editMeetingExitQuestionnaires(id, payload) {
    return axios.patch(`/meeting/${id}/exitQuestionnaires`, payload);
  },
  checkPossibleCheckIn(id) {
    return axios.get(`/meeting/${id}/check-in`);
  },
  getCheckOutData(id) {
    return axios.get(`/meeting/${id}/check-out`);
  },
  instantReplacementCheckin(payload) {
    return axios.post('/meeting/instantReplacementCheckin', {
      ...payload
    })
  },
  checkIn(payload) {
    return axios.patch(`/meeting/${payload.id}/check-in`, payload);
  },
  checkOut(payload) {
    return axios.patch(`/meeting/${payload.id}/check-out`, {
      tempMeasure1: payload.tempMeasure1,
      tempMeasure2: payload.tempMeasure2,
      comments: payload.comments,
      questionnairesAnswers: payload.questionnairesAnswers
    });
  },
  checkInBack(id) {
    return axios.patch(`/meeting/${id}/check-in-back`);
  },
  removeMeeting(id) {
    return axios.delete(`/meeting/${id}`);
  },
  cancel(id) {
    return axios.patch(`/meeting/${id}/cancel`);
  },
  findEmployees(buildingId, name) {
    return axios.get(`/meeting/building/${buildingId}/employee`, {params: {name: name}});
  },
  getQuestionnaireToAnswer(meetingId) {
    return axios.get(`/meeting/${meetingId}/questionnaire`)
  },
  getExitQuestionnaireToAnswer(meetingId) {
    return axios.get(`/meeting/${meetingId}/exitQuestionnaire`)
  }
};
