import axios from "axios";

export default {
  getUserRoles() {
    return axios.get("/user_roles");
  },
  getPossibleValues() {
    return axios.get("/preferences/possibleValues");
  },
};
