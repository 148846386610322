import * as types from "@/store/mutation-types";
import api from "@/services/api/exposed";
import { convertDateTimes } from "@/utils/utils";

const getters = {
  rescheduleVisitData: (state) => state.data,
  rescheduleVisitStatus: (state) => state.status,
  rescheduleVisitLoading: (state) => state.loading,
};

const actions = {
  getRescheduleVisitData({ commit }, identifier) {
    return new Promise((resolve) => {
      api
        .getRescheduleVisitData(identifier)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ERESCHEDULE_VISIT_DATA, response.data);
            resolve();
          }
        })
        .catch(() => {
          commit(types.ERROR, "CANCEL_VISIT_NOT_FOUND");
        });
    });
  },
  rescheduleVisit({ commit }, payload) {
    commit(types.ERESCHEDULE_VISIT_LOADING, true);
    return new Promise((resolve) => {
      api
        .rescheduleVisit(payload.identifier, payload.scheduledAt)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ERESCHEDULE_VISIT_LOADING, false);
            commit(types.ERESCHEDULE_VISIT_STATUS, "success");
            resolve();
          }
        })
        .catch(() => {
          commit(types.ERESCHEDULE_VISIT_LOADING, false);
          commit(types.ERESCHEDULE_VISIT_STATUS, "error");
          resolve();
        });
    });
  },
};

const mutations = {
  [types.ERESCHEDULE_VISIT_DATA](state, data) {
    convertDateTimes(data);
    state.data = data;
  },
  [types.ERESCHEDULE_VISIT_STATUS](state, status) {
    state.status = status;
  },
  [types.ERESCHEDULE_VISIT_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
};

const state = {
  data: {},
  status: "pending",
  loading: false,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
