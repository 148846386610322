import * as types from "@/store/mutation-types";
import api from "@/services/api/adminBuildings";
import adminQuestionnaires from "@/services/api/adminQuestionnaires";
import {buildSuccess, convertDateTimes, handleError, handleErrorCode} from "@/utils/utils.js";

const getters = {
    buildings: (state) => state.buildings,
    totalBuildings: (state) => state.totalBuildings,
    editedBuilding: (state) => state.editedBuilding,
    availableQuestionnaires: (state) => state.availableQuestionnaires,
    uploadCheckInLogoProgress: (state) => state.uploadCheckInLogoProgress,
    timeZones: (state) => state.timeZones,
    countries: (state) => state.countries,
    buildingLocales: (state) => state.buildingLocales,
};

const actions = {
    getBuildings({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getBuildings(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.BUILDINGS, response.data.docs);
                        commit(types.TOTAL_BUILDINGS, response.data.totalDocs);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    loadBuildingQuestionnairesDetails({commit}, item) {
        commit(types.BUILDING_TO_EDIT, item);
        return new Promise((resolve, reject) => {
            const data = {};
            api
                .getBuildingQuestionnaires(item.id)
                .then((response) => {
                    if (response.status === 200) {
                        data.contractorQuestionnaires = response.data
                            .filter((i) => i.visitorType === "CONTRACTOR")
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map((i) => {
                                return i.questionnaire;
                            });
                        data.visitorQuestionnaires = response.data
                            .filter((i) => i.visitorType === "VISITOR")
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map((i) => {
                                return i.questionnaire;
                            });
                        data.employeeQuestionnaires = response.data
                            .filter((i) => i.visitorType === "EMPLOYEE")
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map((i) => {
                                return i.questionnaire;
                            });
                        commit(types.BUILDING_TO_EDIT_UPDATE, data);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    loadBuildingExitQuestionnairesDetails({commit}, item) {
        return new Promise((resolve, reject) => {
            const data = {};
            api
                .getBuildingExitQuestionnaires(item.id)
                .then((response) => {
                    if (response.status === 200) {
                        data.contractorExitQuestionnaires = response.data
                            .filter((i) => i.visitorType === "CONTRACTOR")
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map((i) => {
                                return i.questionnaire;
                            });
                        data.visitorExitQuestionnaires = response.data
                            .filter((i) => i.visitorType === "VISITOR")
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map((i) => {
                                return i.questionnaire;
                            });
                        data.employeeExitQuestionnaires = response.data
                            .filter((i) => i.visitorType === "EMPLOYEE")
                            .sort((a, b) => {
                                return a.order - b.order;
                            })
                            .map((i) => {
                                return i.questionnaire;
                            });
                        commit(types.BUILDING_TO_EDIT_UPDATE, data);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    editBuilding({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .editAddressContact(payload.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.SAVED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    uploadCheckInLogo({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .uploadCheckInLogo(payload, (event) => {
                    const result = Math.round((100 * event.loaded) / event.total);
                    commit(types.CHECK_IN_LOGO_UPLOAD_PROGRESS, result);
                })
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.SAVED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                        commit(types.CHECK_IN_LOGO_UPLOAD_PROGRESS, 0);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                    commit(types.CHECK_IN_LOGO_UPLOAD_PROGRESS, 0);
                });
        });
    },
    editGeneralSettings({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .editBuildingSettings(payload.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            handleErrorCode(response.data.errorCode, commit, reject);
                        } else {
                            buildSuccess(
                                {
                                    msg: "common.SAVED_SUCCESSFULLY",
                                },
                                commit,
                                resolve
                            );
                        }
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    editCheckInFormSettings({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .editBuildingCheckInForms(payload.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.SAVED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    saveBuilding({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .saveBuilding(payload)
                .then((response) => {
                    if (response.data && response.data.errorCode) {
                        handleErrorCode(response.data.errorCode, commit, reject);
                    } else {
                        buildSuccess(
                            {
                                msg: "common.SAVED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                        commit(types.BUILDING_TO_EDIT, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    deleteBuilding({commit}, id) {
        return new Promise((resolve, reject) => {
            api
                .deleteBuilding(id)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.DELETED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    getAvailableQuestionnaires({commit}) {
        return new Promise((resolve, reject) => {
            adminQuestionnaires
                .availableQuestionnaires()
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.AVAILABLE_QUESTIONNAIRES, response.data);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    saveBuildingQuestionnaires({commit}, payload) {
        const data = {
            id: payload.id,
            successFormText: payload.successFormText,
            failureFormText: payload.failureFormText,
            contractorQuestionnaires: payload.contractorQuestionnaires,
            visitorQuestionnaires: payload.visitorQuestionnaires,
            employeeQuestionnaires: payload.employeeQuestionnaires,
        };
        return new Promise((resolve, reject) => {
            api
                .saveBuildingQuestionnaires(data.id, data)
                .then((response) => {
                    if (response.status === 201) {
                        buildSuccess(
                            {
                                msg: "common.SAVED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                        //commit(types.BUILDING_TO_EDIT, response.data);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    saveBuildingExitQuestionnaires({commit}, payload) {
        const data = {
            id: payload.id,
            contractorQuestionnaires: payload.contractorExitQuestionnaires,
            visitorQuestionnaires: payload.visitorExitQuestionnaires,
            employeeQuestionnaires: payload.employeeExitQuestionnaires,
            allowExitQuestionnairesOnEmailCheckOut: payload.allowExitQuestionnairesOnEmailCheckOut
        };
        return new Promise((resolve, reject) => {
            api
                .saveBuildingExitQuestionnaires(data.id, data)
                .then(() => {
                    buildSuccess(
                        {
                            msg: "common.SAVED_SUCCESSFULLY",
                        },
                        commit,
                        resolve
                    );
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    getDictionaries({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getDictionaries(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.COUNTRIES, response.data.countries);
                        commit(types.TIME_ZONES, response.data.timeZones);
                        commit(types.BUILDING_LOCALES, response.data.defaultLocales);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    updateDevices({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .updateDevices(payload.id, payload.devicesIds)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.SAVED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                        commit(types.BUILDING_TO_EDIT_UPDATE, {devicesIds: response.data.devicesIds});
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    saveBuildingDeviceTexts({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .saveBuildingDeviceTexts(payload.id,
                    {successText: payload.devicesSuccessText, attentionText: payload.devicesAttentionText})
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.SAVED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    }
};

const mutations = {
    [types.BUILDINGS](state, buildings) {
        convertDateTimes(buildings);
        state.buildings = buildings;
    },
    [types.TOTAL_BUILDINGS](state, buildings) {
        state.totalBuildings = buildings;
    },
    [types.BUILDING_TO_EDIT](state, building) {
        convertDateTimes(building);
        state.editedBuilding = building;
    },
    [types.BUILDING_TO_EDIT_UPDATE](state, data) {
        state.editedBuilding = {
            ...state.editedBuilding,
            ...data
        }
    },
    [types.AVAILABLE_QUESTIONNAIRES](state, questionnaires) {
        state.availableQuestionnaires = questionnaires;
    },
    [types.CHECK_IN_LOGO_UPLOAD_PROGRESS](state, result) {
        state.uploadCheckInLogoProgress = result;
    },
    [types.COUNTRIES](state, data) {
        state.countries = data;
    },
    [types.TIME_ZONES](state, data) {
        data.splice(0, 0, {text: "", value: ""});
        state.timeZones = data;
    },
    [types.BUILDING_LOCALES](state, data) {
        state.buildingLocales = data;
    },
};

const state = {
    buildings: [],
    totalBuildings: 0,
    editedBuilding: {},
    availableQuestionnaires: [],
    uploadCheckInLogoProgress: 0,
    timeZones: [],
    countries: [],
    buildingLocales: [],
};

export default {
    state,
    getters,
    actions,
    mutations,
};
