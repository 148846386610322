<template>
  <v-footer fixed height="50" class="d-block text-center">
    {{ appName }} - v{{ appVersion }}
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    appVersion() {
      return this.$store.state.app.appVersion;
    },
    appName() {
      return process.env.VUE_APP_NAME_LABEL;
    },
  },
};
</script>
