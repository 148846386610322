import axios from "axios";

export default {
  getNotVerifiedUser(token) {
    return axios.get("user/byVerificationToken", {
      params: {
        token,
      },
    });
  },
  sendVerify(payload) {
    return axios.post("/user/verify", payload);
  },
};
