import * as types from "@/store/mutation-types";
import api from "@/services/api/adminPeople";
import {buildSuccess, convertDateTimes, handleError, handleErrorCode} from "@/utils/utils.js";

const getters = {
    people: (state) => state.people,
    totalPeople: (state) => state.totalPeople,
    personStats: (state) => state.personStats,
    totalPersonStats: (state) => state.totalPersonStats
};

const actions = {
    getPeople({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getPeople(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.PEOPLE, response.data.docs);
                        commit(types.TOTAL_PEOPLE, response.data.totalDocs);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doUpdatePerson({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .updatePerson(payload.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            handleErrorCode(response.data.errorCode, commit, reject);
                        } else {
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doCreatePerson({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .createPerson(payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            handleErrorCode(response.data.errorCode, commit, reject);
                        } else {
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    } else {
                        handleError(response, commit, reject);
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doDeletePerson({commit}, id) {
        return new Promise((resolve, reject) => {
            api
                .deletePerson(id)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.DELETED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doSendPass({commit}, id) {
        return new Promise((resolve, reject) => {
            api
                .sendPass(id)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "people.passSent",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doSendLink({commit}, id) {
        return new Promise((resolve, reject) => {
            api
                .sendLink(id)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "people.linkSent",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    showPersonQrCode({commit}, id) {
        return new Promise((resolve, reject) => {
            api
                .getPersonQrCode(id)
                .then((response) => {
                    if (response.status === 200) {
                        let selector = 'img[data-qrcode-id="' + id + '"]';
                        let img = document.querySelector(selector);
                        if (img !== null) {
                            img.src = "data:image/png;base64," + response.data.qrcode;
                        }
                        // commit(types.PEOPLE, response.data.docs);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doUploadPeople({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .uploadPeople(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "people." + response.data.msg,
                                params: [response.data.count, response.data.total]
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doGetPersonStats({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getPersonStats(payload.id, payload.params)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.PERSON_STATS, response.data.docs);
                        commit(types.TOTAL_PERSON_STATS, response.data.totalDocs);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    /*
        doGetMeetingData({commit}, id) {
            return new Promise((resolve, reject) => {
                api
                    .getMeetingData(id)
                    .then((response) => {
                        if (response.status === 200) {
                            //
                            resolve();
                        }
                    })
                    .catch((error) => {
                        handleError(error, commit, reject);
                    });
            });
        },
    */
};

const mutations = {
    [types.PEOPLE](state, items) {
        convertDateTimes(items);
        state.people = items;
    },
    [types.TOTAL_PEOPLE](state, count) {
        state.totalPeople = count;
    },
    [types.PERSON_STATS](state, items) {
        convertDateTimes(items);
        state.personStats = items;
    },
    [types.TOTAL_PERSON_STATS](state, count) {
        state.totalPersonStats = count;
    }
};

const state = {
    people: [],
    totalPeople: 0,
    personStats: [],
    totalPersonStats: 0
};

export default {
    state,
    getters,
    actions,
    mutations,
};
