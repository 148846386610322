import * as types from "@/store/mutation-types";
import api from "@/services/api/checkinout";
import { buildSuccess, convertDateTimes, handleError } from "@/utils/utils.js";

const getters = {
    meetings: (state) => state.meetings,
    visitToCheckIn: (state) => state.visitToCheckIn,
    visitToCheckOut: (state) => state.visitToCheckOut,
    entryLogEmployeeDialog: (state) => state.employeeDialog
};

const actions = {
    startCheckOutProcedure({commit}, id) {
        return new Promise((resolve, reject) => {
            api.getCheckOutData(id)
                .then((response) => {
                    commit(types.VISIT_TO_CHECK_OUT_DATA, response.data);
                    commit(types.VISIT_TO_CHECK_OUT_STATUS, 'allowed');
                    resolve();
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    startCheckIn({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .checkPossibleCheckIn(payload.id)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.allowed) {
                            commit(types.VISIT_TO_CHECK_IN_DATA, Object.assign({}, response.data.entryLogItem));
                            commit(types.VISIT_TO_CHECK_IN_STATUS, 'allowed');
                        } else {
                            commit(types.WARNING, {msg: "checkInOut.checkIn.expired"});
                            commit(types.VISIT_TO_CHECK_IN_DATA, Object.assign({}, response.data.entryData))
                            commit(types.VISIT_TO_CHECK_IN_STATUS, 'expired');
                        }
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    exitCheckIn({commit}) {
        commit(types.VISIT_TO_CHECK_IN_DATA, {});
        commit(types.VISIT_TO_CHECK_IN_STATUS, 'none');
    },
    exitCheckOut({commit}) {
        commit(types.VISIT_TO_CHECK_OUT_DATA, {});
        commit(types.VISIT_TO_CHECK_OUT_STATUS, 'none');
    },
    instantReplacementCheckin({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .instantReplacementCheckin(payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.errorCode) {
                            handleError({
                                "response": {
                                    "data": {
                                        "msg": response.data.errorCode
                                    }
                                }
                            }, commit, reject);
                        } else {
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    getMeetings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getMeetings(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.MEETINGS, response.data.docs);
            commit(types.TOTAL_MEETINGS, response.data.totalDocs);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  saveMeeting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveMeeting(payload)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: "common.SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  saveAndCheckInMeeting({ commit }, payload) {
      return new Promise((resolve, reject) => {
          api
              .saveAndCheckInMeeting(payload)
              .then((response) => {
                  if (response.status === 200) {
                      if (response.data.allowed) {
                          commit(types.VISIT_TO_CHECK_IN_DATA, Object.assign({}, response.data.entryLogItem));
                          commit(types.VISIT_TO_CHECK_IN_STATUS, 'allowed');
                      } else {
                          commit(types.VISIT_TO_CHECK_IN_DATA, Object.assign({}, response.data.entryData))
                          commit(types.VISIT_TO_CHECK_IN_STATUS, 'expired');
                      }
                      resolve();
                  }
              })
              .catch((error) => {
                  handleError(error, commit, reject);
              });
      });
  },
  editMeeting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editMeeting(payload.id, payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "common.SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  checkIn({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .checkIn(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "checkInOut.actions.CHECK_IN_SUCCESS",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
            if(error.response.status === 422) {
                commit(types.ERROR, "CHECK_IN_EMPLOYEE_QUESTIONNAIRES_NO_LONGER_VALID");
            } else {
                handleError(error, commit, reject);
            }
        });
    });
  },
  checkOut({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .checkOut(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "checkInOut.actions.CHECK_OUT_SUCCESS",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  checkInBack({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
          .checkInBack(id)
          .then((response) => {
            if (response.status === 200) {
              buildSuccess(
                  {
                    msg: "checkInOut.actions.CHECK_IN_BACK_SUCCESS",
                  },
                  commit,
                  resolve
              );
            }
          })
          .catch((error) => {
            handleError(error, commit, reject);
          });
    });
  },
  removeMeeting({ commit }, id) {
        return new Promise((resolve, reject) => {
            api
                .removeMeeting(id)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "checkInOut.actions.DELETE_SUCCESS",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
  cancelMeeting({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .cancel(id)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "checkInOut.actions.CANCEL_SUCCESS",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
};

const mutations = {
    [types.MEETINGS](state, meetings) {
        convertDateTimes(meetings);
        state.meetings = meetings;
    },
    [types.TOTAL_MEETINGS](state, total) {
        state.totalMeetings = total;
    },
    [types.VISIT_TO_CHECK_IN_DATA](state, data) {
        state.visitToCheckIn.data = Object.assign({tempMeasure1: null, tempMeasure2: null}, data);
    },
    [types.VISIT_TO_CHECK_IN_STATUS](state, status) {
        state.visitToCheckIn.status = status;
    },
    [types.VISIT_TO_CHECK_OUT_DATA](state, data) {
        state.visitToCheckOut.data = Object.assign({tempMeasure1: null, tempMeasure2: null}, data);
    },
    [types.VISIT_TO_CHECK_OUT_STATUS](state, status) {
        state.visitToCheckOut.status = status;
    },
    [types.EMPLOYEE_CHECK_IN_MODAL](state, value) {
        state.employeeDialog = value;
    }
};

const state = {
    meetings: [],
    totalMeetings: 0,
    visitToCheckIn: {
        data: {
            tempMeasure1: null,
            tempMeasure2: null
        },
        status: 'none' //none   allowed     expired
    },
    visitToCheckOut: {
        data: {
            tempMeasure1: null,
            tempMeasure2: null
        },
        status: 'none' //none    allowed
    },
    employeeDialog: false
};

export default {
  state,
  getters,
  actions,
  mutations,
};
