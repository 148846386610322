import api from "@/services/api/adminCameras";
import * as types from "@/store/mutation-types";
import {handleError} from "@/utils/utils";

const getters = {
    buildingCameras: (state) => state.buildingCameras,
};

const actions = {
    loadCamerasByBuilding({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api
                .findCamerasByBuilding(payload.id)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.BUILDING_CAMERAS, response.data);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    createCamera({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api
                .createCamera(payload.buildingId, payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.BUILDING_CAMERAS, response.data);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    updateCamera({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api
                .updateCamera(payload.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.BUILDING_CAMERAS, response.data);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    deleteCamera({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api
                .deleteCamera(payload.id)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.BUILDING_CAMERAS, response.data);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
};

const mutations = {
    [types.BUILDING_CAMERAS](state, cameras) {
        state.buildingCameras = cameras;
    }
};

const state = {
    buildingCameras: []
};

export default {
    state,
    getters,
    actions,
    mutations,
};
