import * as types from "@/store/mutation-types";
import api from "@/services/api/adminUsers";
import { buildSuccess, convertDateTimes, handleError, handleErrorCode } from "@/utils/utils.js";

const getters = {
  users: (state) => state.users,
  totalUsers: (state) => state.totalUsers,
  editedUserBuildings: (state) => state.editedUserBuildings,
};

const actions = {
  getUsers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getUsers(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.USERS, response.data.docs);
            commit(types.TOTAL_USERS, response.data.totalDocs);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  editUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .editUser(payload.id, payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "common.SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  saveUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .saveUser(payload.item)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: "common.SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
            payload.cb(response.data);
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  deleteUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteUser(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "common.DELETED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  doChangePasswordByAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .changePasswordByAdmin(payload.id, payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: "common.SAVED_SUCCESSFULLY",
              },
              commit,
              resolve
            );
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  doGetUserBuildings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getUserBuildings(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.EDITED_USER_BUILDINGS, response.data);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
  doUpdateUserBuilding({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateUserBuilding(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success) {
              buildSuccess(
                  {
                    msg: "users.buildings." + response.data.operation,
                  },
                  commit,
                  resolve
              );
            } else {
              handleErrorCode("users.buildings.failed." + response.data.operation, commit, reject);
            }
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
};

const mutations = {
  [types.USERS](state, users) {
    convertDateTimes(users);
    state.users = users;
  },
  [types.TOTAL_USERS](state, value) {
    state.totalUsers = value;
  },
  [types.EDITED_USER_BUILDINGS](state, value) {
    state.editedUserBuildings = value;
  },
};

const state = {
  users: [],
  totalUsers: 0,
  editedUserBuildings: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
