import axios from "axios";

export default {
  getPeople(params) {
    return axios.get("/people", {
      params,
    });
  },
  getPeopleCsv(params) {
    const method = 'GET';
    const url = '/people/people.csv';
    axios.request({
      url,
      method,
      params,
      responseType: 'blob'
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'people.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },
  createPerson(payload) {
    return axios.post("/person", payload);
  },
  updatePerson(id, payload) {
    return axios.patch(`/person/${id}`, payload);
  },
  deletePerson(id) {
    return axios.delete(`/person/${id}`);
  },
  sendPass(id) {
    return axios.post(`/person/${id}/sendpass`);
  },
  sendLink(id) {
    return axios.post(`/person/${id}/sendlink`);
  },
  uploadPeople(payload) {
    let formData = new FormData();
    formData.append("file", payload.file, 'people.csv');
    formData.append("tenantId", payload.tenantId);
    formData.append("columns", payload.columns);
    formData.append("datesFormat", payload.datesFormat);
    formData.append("visitorType", payload.visitorType);
    formData.append("firstRow", payload.firstRow);

    return axios.patch(`/people/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  },
  getPersonStats(id, params) {
    return axios.get(`/person/${id}/stats`, {
      params
    });
  },
  getMeetingData(meetingId) {
    return axios.get(`/meeting/${meetingId}`);
  }
};
