export default [
  {
    path: "/spacebooking/calendar",
    name: "spaceBookingCalendar",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "spacecalendar" */ "@/components/spaceBooking/SpaceCalendar.vue"),
  },
  {
    path: "/spacebooking/map",
    name: "spaceBookingMap",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "spacebooking" */ "@/components/spaceBooking/SpaceCalendar.vue"),
  },
  {
    path: "/spacebooking/approval/:id",
    name: "spaceBookingApproval",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "spacebooking" */ "@/components/spaceBooking/SpaceBookingApproval.vue"),
  },
  
];
