import * as types from "@/store/mutation-types";
import api from "@/services/api/tenants";
import { handleError } from "@/utils/utils.js";
import auth from "@/store/modules/auth";

const getters = {
  allTenants: (state) => state.allTenants,
  myTenant: (state) => state.myTenant,
  tenantBuildings: (state) => state.tenantBuildings,
};

const actions = {
  doSelectTenant({ commit }, tenantId) {
    console.log('doSelectTenant', tenantId);
    if (auth.getters.isAdmin || auth.getters.isTech) {
      return callSelectTenant(commit, tenantId);
    } else {
      return callSelectTenant(commit);
    }
  },
  setMyTenant({ commit }) {
    if (auth.getters.isAdmin || auth.getters.isTech) {
      const tenantId = JSON.parse(localStorage.getItem("tenant"));
      return callSelectTenant(commit, tenantId);
    } else {
      return callSelectTenant(commit);
    }
  }
};
const callSelectTenant = function(commit, tenantId) {
  return new Promise((resolve, reject) => {
    api.selectTenant(tenantId).
    then((response) => {
      if (response.status === 200) {
        commit(types.FILL_MY_TENANT, response.data);
        commit(types.TENANT_BUILDINGS, response.data.buildings);
        commit(types.FILL_ALL_TENANTS, response.data.tenants);
        resolve();
      }
    }).catch((error) => {
      handleError(error, commit, reject);
    });
  });
}

const mutations = {
  [types.FILL_ALL_TENANTS](state, tenants) {
    state.allTenants = tenants;
  },
  [types.FILL_MY_TENANT](state, data) {
    let tenant = data.tenant;
    if (tenant) {
      if (data.supertenant) {
        tenant.supertenant = true;
      }
      localStorage.setItem("tenant", tenant.id);
    }
    state.myTenant = tenant;
  },
  [types.TENANT_BUILDINGS](state, buildings) {
    state.tenantBuildings = buildings;
  }
};

const state = {
  allTenants: [],
  myTenant: {},
  tenantBuildings: []
};

export default {
  state,
  getters,
  actions,
  mutations,
};
