import { getFormat, getFormatDate, getFormatTime } from "@/utils/utils";

export default {
  methods: {
    formatDT(dateTime, skipSeconds = false) {
      return getFormat(dateTime, skipSeconds);
    },
    formatDate(dateTime) {
      return getFormatDate(dateTime);
    },
    formatTime(dateTime) {
      return getFormatTime(dateTime);
    },
    nameTimePattern(format) {
      return this.$t("users.timeFormat." + format.value);
    },
  },
};
