import axios from "axios";

export default {
  getByResetPasswordToken(token) {
    return axios.get("/user/byResetPasswordToken", {
      params: {
        token,
      },
    });
  },
  forgotPassword(payload) {
    return axios.post("/forgot", payload);
  },
};
