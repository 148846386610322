import axios from "axios";

export default {
  getTenants(params) {
    return axios.get("/tenant", {
      params,
    });
  },
  editTenant(id, payload) {
    return axios.patch(`/tenant/${id}`, payload);
  },
  createTenantWithMaster(payload) {
    return axios.post("/tenantWithMaster", payload);
  },
  deleteTenant(id) {
    return axios.delete(`/tenant/${id}`);
  },
};
