// app
export const SET_APP_VERSION = "SET_APP_VERSION";
export const SET_APP_BACKGROUND = "SET_APP_BACKGROUND";
export const SET_APP_HOMELINK = "SET_APP_HOMELINK";
export const SET_APP_FROM_KIOSK = "SET_APP_FROM_KIOSK";
export const SET_APP_CURRENT_BUILDING = "SET_APP_CURRENT_BUILDING";
// locale
export const SET_LOCALE = "SET_LOCALE";
// error
export const SHOW_ERROR = "SHOW_ERROR";
export const ERROR = "ERROR";
// success
export const SHOW_SUCCESS = "SHOW_SUCCESS";
export const SUCCESS = "SUCCESS";
// warning
export const SHOW_WARNING = "SHOW_WARNING";
export const WARNING = "WARNING";
// loading
export const SHOW_LOADING = "SHOW_LOADING";
// auth
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SAVE_USER = "SAVE_USER";
export const SAVE_ORIGINAL_TOKEN = "SAVE_ORIGINAL_TOKEN";
export const SAVE_ORIGINAL_USER = "SAVE_ORIGINAL_USER"
export const SAVE_ORIGINAL_USER_AND_TOKEN = "SAVE_ORIGINAL_USER_AND_TOKEN";
export const RESTORE_ORIGINAL_USER_AND_TOKEN = "RESTORE_ORIGINAL_USER_AND_TOKEN";
export const SAVE_USER_PREFERENCES = "SAVE_USER_PREFERENCES";
export const LOGOUT = "LOGOUT";
export const NOT_VERIFIED_USER = "NOT_VERIFIED_USER";
export const RESET_EMAIL_SENT = "RESET_EMAIL_SENT";
export const SHOW_CHANGE_PASSWORD_INPUTS = "SHOW_CHANGE_PASSWORD_INPUTS";
export const USER_TO_CHANGE_PASSWORD = "USER_TO_CHANGE_PASSWORD";
export const REDIRECT_PATH = "REDIRECT_PATH";
// profile
export const USER_LOCALES = "USER_LOCALES";
export const DATE_FORMATS = "DATE_FORMATS";
export const TIME_FORMATS = "TIME_FORMATS";
export const FILL_PROFILE = "FILL_PROFILE";
export const PROFILE_BUILDINGS = "PROFILE_BUILDINGS";
export const ADD_PROFILE_DATA = "ADD_PROFILE_DATA";
// tenants
export const FILL_ALL_TENANTS = "FILL_ALL_TENANTS";
export const FILL_MY_TENANT = "FILL_MY_TENANT";
export const TENANT_BUILDINGS = "TENANT_BUILDINGS";
// Admin - Tenants
export const TENANTS = "TENANTS";
export const TOTAL_TENANTS = "TOTAL_TENANTS";
// Admin - Users
export const USERS = "USERS";
export const TOTAL_USERS = "TOTAL_USERS";
export const EDITED_USER_BUILDINGS = "EDITED_USER_BUILDINGS";
// Admin - Buildings
export const BUILDING_TO_EDIT = "BUILDING_TO_EDIT";
export const BUILDINGS = "BUILDINGS";
export const TOTAL_BUILDINGS = "TOTAL_BUILDINGS";
export const AVAILABLE_QUESTIONNAIRES = "AVAILABLE_QUESTIONNAIRES";
export const CHECK_IN_LOGO_UPLOAD_PROGRESS = "CHECK_IN_LOGO_UPLOAD_PROGRESS";
export const COUNTRIES = "COUNTRIES";
export const TIME_ZONES = "TIME_ZONES";
export const BUILDING_LOCALES = "BUILDING_LOCALES";
export const BUILDING_TO_EDIT_UPDATE = "BUILDING_TO_EDIT_EXIT_UPDATE";
export const BUILDING_CAMERAS = "BUILDING_CAMERAS";
// Config
export const USER_ROLES = "USER_ROLES";
// Check-in/out
export const MEETINGS = "MEETINGS";
export const TOTAL_MEETINGS = "TOTAL_MEETINGS";
export const VISIT_TO_CHECK_IN_DATA = "VISIT_TO_CHECK_IN_DATA";
export const VISIT_TO_CHECK_IN_STATUS = "VISIT_TO_CHECK_IN_STATUS";
export const VISIT_TO_CHECK_OUT_DATA = "VISIT_TO_CHECK_OUT_DATA";
export const VISIT_TO_CHECK_OUT_STATUS = "VISIT_TO_CHECK_OUT_STATUS";
export const EMPLOYEE_CHECK_IN_MODAL = "EMPLOYEE_CHECK_IN_MODAL";
export const CHECKIN_EMPLOYEES_LOADED = "CHECKIN_EMPLOYEES_LOADED";
// Admin - Questionnaires
export const QUESTIONNAIRES = "QUESTIONNAIRES";
export const TOTAL_QUESTIONNAIRES = "TOTAL_QUESTIONNAIRES";
export const QUESTIONS = "QUESTIONS";
// ADMIN TESTS
export const HEALTH = "HEALTH";
// Admin logs
export const LOGS = "LOGS";
export const LOGS_ITEM_ID = "LOGS_ITEM_ID";
export const LOGS_ITEM_TYPE = "LOGS_ITEM_TYPE";
// exposed Check-in
export const ECHECKIN_INITIAL_DATA = "ECHECKIN_INITIAL_DATA";
export const ECHECKIN_DATA = "ECHECKIN_DATA";
export const ECHECKIN_STATUS = "ECHECKIN_STATUS";
export const ECHECKIN_STATUS_DETAILS = "ECHECKIN_STATUS_DETAILS";
// exposed Registration
export const EREGISTRATION_DATA = "EREGISTRATION_DATA";
export const EREGISTRATION_STATUS = "EREGISTRATION_STATUS";
// exposed Cancel Visit
export const ECANCEL_VISIT_DATA = "ECANCEL_VISIT_DATA";
export const ECANCEL_VISIT_STATUS = "ECANCEL_VISIT_STATUS";
export const ECANCEL_VISIT_LOADING = "ECANCEL_VISIT_LOADING";
// exposed Postpone CheckOut
export const EPOSTPONE_CHECKOUT_DATA = "EPOSTPONE_CHECKOUT";
export const EPOSTPONE_CHECKOUT_STATUS = "EPOSTPONE_CHECKOUT_STATUS";
export const EPOSTPONE_CHECKOUT_LOADING = "EPOSTPONE_CHECKOUT_LOADING";
// exposed Reschedule Visit
export const ERESCHEDULE_VISIT_DATA = "ERESCHEDULE_VISIT_DATA";
export const ERESCHEDULE_VISIT_STATUS = "ERESCHEDULE_VISIT_STATUS";
export const ERESCHEDULE_VISIT_LOADING = "ERESCHEDULE_VISIT_LOADING";
// exposed CheckOut
export const ECHECKOUT_DATA = "ECHECKOUT_DATA";
export const ECHECKOUT_STATUS = "ECHECKOUT_STATUS";
export const ECHECKOUT_STATUS_DETAILS = "ECHECKOUT_STATUS_DETAILS";
export const ECHECKOUT_LOADING = "ECHECKOUT_LOADING";
export const BUILDING_CHECKOUT_DATA = "BUILDING_CHECKOUT_DATA";
export const FOUND_MEETING = "FOUND_MEETING";
// Admin People
export const PEOPLE = "PEOPLE";
export const TOTAL_PEOPLE = "TOTAL_PEOPLE";
export const PERSON_STATS = "PERSON_STATS";
export const TOTAL_PERSON_STATS = "TOTAL_PERSON_STATS";
//exposed PIN identification
export const PIN_IDENTIFICATION_STATUS = "PIN_IDENTIFICATION_STATUS";
export const PIN_IDENTIFICATION_DATA = "PIN_IDENTIFICATION_DATA";
//Admin Floor
export const FLOORS = "FLOORS";
export const TOTAL_FLOORS= "TOTAL_FLOORS";
export const FLOOR_STATS= "FLOOR_STATS";
export const TOTAL_FLOOR_STATS= "TOTAL_FLOOR_STATS";
export const LAYOUT_FILE_UPLOAD_PROGRESS = "LAYOUT_FILE_UPLOAD_PROGRESS";
export const FLOOR_LAYOUT = "FLOOR_LAYOUT";
export const USER_FLOORS = "USER_FLOORS";
//Admin Space
export const SPACES = "SPACES";
export const TOTAL_SPACES= "TOTAL_SPACES";
export const SPACE_STATS= "SPACE_STATS";
export const TOTAL_SPACE_STATS= "TOTAL_SPACE_STATS";
export const SPACE= "SPACE";
export const SPACE_APPROVER= "SPACE_APPROVER";
export const SPACE_LIST="SPACE_LIST"
export const SPACE_LIST_TMP="SPACE_LIST_TMP"
//Space Booking
export const SPACEBOOKINGS = "SPACEBOOKINGS";
export const TOTAL_SPACEBOOKINGS= "TOTAL_SPACEBOOKINGS";
export const SPACEBOOKING_STATS= "SPACEBOOKING_STATS";
export const TOTAL_SPACEBOOKING_STATS= "TOTAL_SPACEBOOKING_STATS";
export const SPACEBOOKINGS_FLOOR= "SPACEBOOKINGS_FLOOR";
export const SPACEBOOKING_PEOPLE= "SPACEBOOKING_PEOPLE";
export const SPACEBOOKING_CREATE_STATUS= "SPACEBOOKING_CREATE_STATUS";
export const SPACEBOOKING_UPDATA_STATUS= "SPACEBOOKING_UPDATA_STATUS";
export const SPACEBOOKING_APPROVAL="SPACEBOOKING_APPROVAL";





