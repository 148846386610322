import axios from "axios";
//Floor Serivce
export default {
  //get all floors for datetable without layout config
  getFloor(params) {
    return axios.get("/floor", {
      params,
    });
  },
  //get Current User's floors
  getUserFloors() {
    return axios.get(`/public/floor/user`);
  },
  //create floor entity
  createFloor(payload) {

    return axios.post("/floor", payload);
  },
  //update floor without layout config
  updateFloor(id, payload) {
 
    return axios.patch(`/floor/${id}`, payload);
  },
  //delete floor by id(logical delete)
  deleteFloor(id) {

    return axios.delete(`/floor/${id}`);
  },
  //update floor layout config by id
  updateFloorLayoutConfig(id, payload) {
    return axios.patch(`/floor/${id}/layoutConfig`, payload);
  },
  //get floor layout config by id
  getFloorLayout(id) {
    return axios.get(`/floor/${id}/layout`);
  },
  //upload floor layout file to server
  uploadLayoutFile(payload, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", payload.layoutFile, payload.layoutFile.name);
    return axios.patch(`/floor/${payload.id}/layoutImage`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  },
  //delete floor layout file (set the null to layoutFile field)
  deleteLayoutFile(id) {

    return axios.delete(`/floor/${id}/layoutImage`);
  },

};
