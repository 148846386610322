export default [
  {
    path: "/booking",
    name: "booking",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/components/Booking.vue"),
  },
];
