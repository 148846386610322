import axios from "axios";

export default {
  getQuestionnaires(params) {
    return axios.get("/questionnaire", {
      params,
    });
  },
  availableQuestionnaires() {
    return axios.get("/questionnaire/all");
  },
  createQuestionnaire(payload) {
    return axios.post("/questionnaire", payload);
  },
  updateQuestionnaire(id, payload) {
    return axios.patch(`/questionnaire/${id}`, payload);
  },
  deleteQuestionnaire(id) {
    return axios.delete(`/questionnaire/${id}`);
  },
  createQuestion(payload) {
    return axios.post(
      `/questionnaire/${payload.questionnaireId}/question`,
      payload
    );
  },
  updateQuestion(payload) {
    return axios.patch(
      `/questionnaire/${payload.questionnaireId}/question/${payload.id}`,
      payload
    );
  },
  deleteQuestion(payload) {
    return axios.delete(
      `/questionnaire/${payload.questionnaireId}/question/${payload.id}`
    );
  },
  moveQuestion(id, questionId, up) {
    return axios.patch(
      `/questionnaire/${id}/question/${questionId}/${up ? "up" : "down"}`
    );
  },
  getQuestions(questionnaireId) {
    return axios.get(`/questionnaire/${questionnaireId}/questions`);
  },
};
