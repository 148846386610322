import axios from "axios";

export default {
  getByResetPasswordToken(token) {
    return axios.get("/user/byResetPasswordToken", {
      params: {
        token,
      },
    });
  },
  resetPassword(payload) {
    return axios.post("/reset", payload);
  },
  adminResetPasswordStart(userId) {
    return axios.post(`/user/${userId}/resetPassword`);
  },
};
