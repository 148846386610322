import axios from "axios";

export default {
  selectTenant(tenantId) {
    if (tenantId) {
      return axios.get(`/selectTenant/${tenantId}`);
    }
    return axios.get(`/selectTenant`);
  },
};
