import axios from "axios";

export default {
  getBuildings(params) {
    return axios.get("/building", {
      params,
    });
  },
  saveBuilding(payload) {
    return axios.post("/building", payload);
  },
  editAddressContact(id, payload) {
    return axios.patch(`/building/${id}`, payload);
  },
  editBuildingSettings(id, payload) {
    delete payload.checkInLogo;
    return axios.patch(`/building/${id}/settings`, payload);
  },
  editBuildingCheckInForms(id, payload) {
    delete payload.checkInLogo;
    return axios.patch(`/building/${id}/checkInForms`, payload);
  },
  uploadCheckInLogo(payload, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", payload.checkInLogo, payload.checkInLogo.name);
    return axios.patch(`/building/${payload.id}/checkInLogo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  },
  getBuildingQuestionnaires(id) {
    return axios.get(`/building/${id}/questionnaire`);
  },
  getBuildingExitQuestionnaires(id) {
    return axios.get(`/building/${id}/exitQuestionnaire`);
  },
  getBuildingCheckInData(id, visitorType) {
    return axios.get(`/building/${id}/checkInData`, {
      params: { visitorType },
    });
  },
  saveBuildingQuestionnaires(id, payload) {
    return axios.put(`/building/${id}/questionnaire`, payload);
  },
  saveBuildingExitQuestionnaires(id, payload) {
    return axios.put(`/building/${id}/exitQuestionnaire`, payload);
  },
  saveBuildingDeviceTexts(id, payload) {
    return axios.patch(`/building/${id}/deviceTexts`, payload);
  },
  deleteBuilding(id) {
    return axios.delete(`/building/${id}`);
  },
  updateDevices(id, devices) {
    return axios.patch(`/building/${id}/devices`, {devicesIds: devices})
  },
  getDictionaries(lang) {
    return axios.get(`/building/dictionaries/${lang}`);
  },
};
