<template>
  <vue-simple-markdown :source="source"
                       :emoji="false" :highlight="false" :image="false" :inlineCode="false"
                       :blockquote="false" :table="false" :link="false" :linkify="false"
                       />
</template>

<script>

import Vue from "vue";
import VueSimpleMarkdown from 'vue-simple-markdown'

Vue.use(VueSimpleMarkdown, {});

export default {
  name: "Markdown",
  props: {
    source: String,
  }
};
</script>
