import Vue from "vue";
import * as VeeValidate from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import en from "../locales/en";
import fr from "../locales/fr";
import es from "../locales/es";
import { localize, extend } from "vee-validate";
import { required, email, min, max, confirmed, min_value, max_value } from "vee-validate/dist/rules";
import api from "@/services/api/adminUsers";

const isEmailUnique = (value, { userId }) => {
  return api.countByEmail(value, userId).then((response) => {
    return {
      valid: response.data.valid,
      data: {
        message: "This email is already taken",
      },
    };
  });
};

// The types of validators used in the project
extend("required", required);
extend("confirmed", confirmed);
extend("email", email);
extend("min", min);
extend("max", max);
extend('min_value', {
  ...min_value,
  message: "Value is too small"
});
extend("max_value", max_value);
extend("url", {
  validate: (str) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  },
});
extend("duration", {
  validate: (v) => {
    return v > 0;
  }
});
extend("uniqueEmail", {
  validate: isEmailUnique,
  message: "this email is already taken",
  params: ["userId"],
});
extend("containsUppercase", {
  validate: value => value && /\p{Lu}/u.test(value)
});
extend("containsLowercase", {
  validate: value => value && /\p{Ll}/u.test(value)
});
extend("containsNumber", {
  validate: value => value && /[0-9]/.test(value)
});
extend("containsSpecial", {
  validate: value => value && /[\p{P}\p{S}]/u.test(value)
});
extend("datesRange", {
  validate: (v, {otherDate, maxDays}) => {
    if (!v || !otherDate) {
      return true;
    }
    let days = Math.ceil((v - otherDate) / (24 * 3600000));
    return days <= maxDays;
  },
  params: [
    {name: 'otherDate', isTarget: true},
    {name: 'maxDays'}
  ]
});
export const passwordRule = function() {
  return {
    required: true,
    containsUppercase: true,
    containsLowercase: true,
    min: {length: 7},
  }
};

localize({
  en,
  fr,
  es
});

Vue.use(VeeValidate);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
export default VeeValidate;
