import axios from "axios";

export default {
  getSpace(params) {
    return axios.get("/space", {
      params,
    });
  },
  getSpaceList(id) {
    return axios.get(`/space/list/${id}`
    )
  },
  getSpaceByFloorId(id, params) {
    return axios.get(`/space/byFloorId/${id}`, {
      params
    });
  },

  createSpace(payload) {
    return axios.post("/space", payload);
  },
  updateSpace(id, payload) {
    return axios.patch(`/space/${id}`, payload);
  },
  deleteSpace(id) {
    return axios.delete(`/space/${id}`);
  },
  getSpaceApprover(buildingId) {
    return axios.get(`/space/approvers/${buildingId}`);
  }
};
