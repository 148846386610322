import * as types from "@/store/mutation-types";
import api from "@/services/api/exposed";
import { convertDateTimes } from "@/utils/utils";

const getters = {
  checkOutData: (state) => state.data,
  checkOutStatus: (state) => state.status,
  checkOutStatusDetails: (state) => state.statusDetails,
  checkOutLoading: (state) => state.loading,
  buildingCheckOutData: (state) => state.buildingCheckOutData,
  foundMeeting: (state) => state.foundMeeting
};

const actions = {
  getCheckOutData({ commit }, identifier) {
    return new Promise((resolve) => {
      api
        .getCheckOutData(identifier)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ECHECKOUT_DATA, response.data);
            resolve();
          }
        })
        .catch(() => {
          commit(types.ERROR, "CHECKOUT_VISIT_NOT_FOUND");
        });
    });
  },
  doExposedCheckOut({ commit }, data) {
    commit(types.ECHECKOUT_LOADING, true);
    return new Promise((resolve) => {
      api
        .checkOut(data)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ECHECKOUT_LOADING, false);
            commit(types.ECHECKOUT_STATUS_DETAILS, response.data);
            resolve();
          }
        })
        .catch(() => {
          commit(types.ECHECKOUT_LOADING, false);
          commit(types.ECHECKOUT_STATUS_DETAILS, null);
          resolve();
        });
    });
  },
  getBuildingCheckOutData({ commit }, identifier) {
    return new Promise((resolve) => {
      api.getBuildingCheckOutData(identifier)
          .then((response) => {
            if (response.status === 200) {
              commit(types.BUILDING_CHECKOUT_DATA, response.data);
              resolve();
            }
          })
          .catch(() => {
            commit(types.ERROR, "NOT_FOUND");
            commit(types.BUILDING_CHECKOUT_DATA, null);
          });
    });
  },
  searchMeeting({commit}, data) {
    return new Promise((resolve) => {
      api.searchMeeting(data).then((response) => {
        if (response.status === 200) {
          commit(types.FOUND_MEETING, response.data);
          resolve();
        }
      }).catch(() => {
        commit(types.FOUND_MEETING, '-');
      });
    });
  },
  getCheckOutFormData({commit}, data) {
    return new Promise((resolve) => {
      api.getCheckOutFormData(data).then((response) => {
        if (response.status === 200) {
          commit(types.ECHECKOUT_DATA, response.data);
          resolve();
        }
      }).catch(() => {
        commit(types.ECHECKOUT_DATA, null);
      });
    });
  },
  cancelCheckOut({commit}) {
    // console.log('cancel', this.state);
    commit(types.BUILDING_CHECKOUT_DATA, this.state.checkOut.buildingCheckOutData);
  }
};

const mutations = {
  [types.ECHECKOUT_DATA](state, data) {
    convertDateTimes(data);
    state.data = data;
    state.status = "pending";
  },
  [types.ECHECKOUT_STATUS_DETAILS](state, data) {
    state.statusDetails = data;
    state.status = data == null ? "error" : "success";
  },
  [types.ECHECKOUT_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [types.BUILDING_CHECKOUT_DATA](state, data) {
    state.buildingCheckOutData = data;
    state.data = {};
    state.status = "pending";
    state.foundMeeting = {};
    if (data.fromKiosk !== undefined) {
      this.commit(types.SET_APP_FROM_KIOSK, data.fromKiosk);
      this.commit(types.SET_APP_CURRENT_BUILDING,
          data.fromKiosk && !data.checkOutTemperatureMeasuresEnabled ? data.qrCode : null);
    }
  },
  [types.FOUND_MEETING](state, meeting) {
    convertDateTimes(meeting);
    state.foundMeeting = meeting;
    state.status = "pending";
  }
};

const state = {
  data: {},
  status: "pending",
  statusDetails: null,
  loading: false,
  buildingCheckOutData: null,
  foundMeeting: null
};

export default {
  state,
  getters,
  actions,
  mutations,
};
