import axios from "axios";

export default {
  getSpaceBooking(params) {
    return axios.get("/spacebooking", {
      params,
    });
  },
  getSpaceBookingList(params) {
    return axios.get("/spacebooking/test", {
      params,
    });
  },
  createSpaceBooking(payload) {
    return axios.post("/spacebooking", payload);
  },
  updateSpaceBooking(id, payload) {
    return axios.patch(`/spacebooking/${id}`, payload);
  },
  updateSpaceBookingApprove(id, payload) {
    return axios.patch(`/spacebooking/approve/${id}`, payload);
  },
  deleteSpaceBooking(id,payload) {
    return axios.delete(`/spacebooking/${id}`,{data:payload});
  },
  getSpaceBookingByFloor(floorId,startDate, endDate) {
    return axios.get(`/public/spacebooking/list/${floorId}/${startDate}/${endDate}`);
  },
  getSpaceBookingApprovalById(id) {
    return axios.get(`/public/spacebooking/approval/${id}`);
  },
  getSpaceBookingPeople(buildingId) {
    return axios.get(`/public/spacebooking/people/${buildingId}`);
  },
  doSendSpaceBookingEmail(payload){
    return axios.post(`/public/spacebooking/email`, payload,
     {headers:{'Content-Type': 'application/x-www-form-urlencoded'}
    });
  }
};
