import axios from "axios";

export default {
  //register:
  getRegistrationData(hash, visitorType) {
    return axios.get(`/registration/${hash}`, {
      params: { visitorType },
    });
  },
  register(payload) {
    return axios.post("/registration", payload);
  },
  //check-in:
  getCheckInInitialData(hash, fromKiosk) {
    if (fromKiosk) {
      return axios.get(`/checkinKiosk/${hash}`);
    }
    return axios.get(`/checkin/${hash}`);
  },
  getCheckInData(hash, identifier, fromKiosk) {
    if (fromKiosk) {
      return axios.get(`/checkinKiosk/${hash}/${identifier}`);
    }
    return axios.get(`/checkin/${hash}/${identifier}`);
  },
  getCheckInStatusDetails(hash, identifier, fromKiosk) {
    if (fromKiosk) {
      return axios.get(`/checkinKiosk/status/${hash}/${identifier}`);
    }
    return axios.get(`/checkin/status/${hash}/${identifier}`);
  },
  checkIn(payload, fromKiosk) {
    if (fromKiosk) {
      return axios.post(`/checkinKiosk`, payload);
    }
    return axios.post(`/checkin`, payload);
  },
  //cancel:
  getCancelableMeetingById(identifier) {
    return axios.get(`/cancelable/${identifier}`);
  },
  cancelMeeting(identifier) {
    return axios.delete(`/cancelable/${identifier}`);
  },
  //postpone checkout:
  getPostponeCheckoutData(identifier) {
    return axios.get(`/postponable/${identifier}`);
  },
  postponeCheckout(identifier, scheduledDuration) {
    return axios.patch(`/postponable/${identifier}`, {
      scheduledDuration,
    });
  },
  //reschedule visit:
  getRescheduleVisitData(identifier) {
    return axios.get(`/reschedulable/${identifier}`);
  },
  rescheduleVisit(identifier, scheduledAt) {
    return axios.patch(`/reschedulable/${identifier}`, {
      scheduledAt,
    });
  },
  //check out:
  getCheckOutData(identifier) {
    return axios.get(`/checkoutable/${identifier}`);
  },
  checkOut(data) {
    return axios.patch(`/checkoutable/${data.publicIdentifier}`, data);
  },
  getBuildingCheckOutData(identifier) {
    return axios.get(`/buildingCheckOutData/${identifier}`);
  },
  searchMeeting(data) {
    if(data.lastname==null||data.lastname.lengh=='')
    return axios.get(`/searchMeeting/${data.identifier}/${data.pin}`);
  else
    return axios.get(`/searchMeeting/${data.identifier}/${data.pin}/${data.lastname}`);

  },
  getCheckOutFormData(identifier) {
    return axios.get(`/checkOutData/${identifier}`);
  },
};
