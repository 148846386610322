import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import * as types from "@/store/mutation-types";
import {cToF, fToC, temperatureMeasureScales, availableLanguages} from "@/components/common/commons";

const DEVICES_SEP = ';;;';

export default {
  metaInfo() {
    return {
      title: this.$store.getters.appTitle,
      titleTemplate: `${this.$t("buildings.TITLE")} - %s`,
    };
  },
  data() {
    return {
      defaultData: {
        meetingStartReminderTime: "PT24H",
        defaultMeetingDuration: "PT1H",
        meetingEndReminderTime: "PT5M",
        overdueAlertTime: "PT15M",
        questionnairesValidityTimeout: "PT2H",
        autoCheckOutTimeout: "PT1H",
        temperatureMeasureUnit: "CELSIUS",
        timeZoneId: "America/Toronto",
        defaultLocale: "en",
        pinIdentificationMethodType: "PERSONAL_PIN_AND_LASTNAME"
      },
      showPin: false,
      editedItem: {},
      buildingLogoRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          this.$t("buildings.edit.validation.checkInLogo.sizeTooBig"),
        (value) =>
          !value ||
          this.$refs.fileInput.$attrs.accept.indexOf(value.type) > -1 ||
          this.$t("buildings.edit.validation.checkInLogo.invalidFormat"),
      ],
      fieldChecked: true,
      autoCheckoutMode: "",
      logoDisplayed: false,
      questionnairePreviewDisplayed: false,
      questionnaireToPreview: {},
      checkInLogoUploadInvalid: false,
      tabsChanged: {
        generalSettings: false,
        addressContact: false,
        checkInForm: false,
        questionnaires: false,
        exitQuestionnaires: false,
        cameras: false,
        devices: false
      },
      camerasLoading: false,
      devicesLoading: false,
      newDeviceName: '',
      activeTab: 0,
      logoPath: "",
    };
  },
  computed: {
    ...mapGetters([
      "isAdmin",
      "isTech",
      "myTenant",
      "user",
      "editedBuilding",
      "availableQuestionnaires",
      "dateFormats",
      "timeFormats"
    ]),
    uploadCheckInLogoProgress: {
      get() {
        return this.$store.state.adminBuildings.uploadCheckInLogoProgress;
      },
      set(value) {
        this.$store.commit(types.CHECK_IN_LOGO_UPLOAD_PROGRESS, value);
      },
    },
    formTitle() {
      return this.editedItem.id
        ? this.$t("buildings.headers.edit")
        : this.$t("buildings.headers.add");
    },
    contractorKeys() {
      return this.formSettingKeys(this.editedItem.contractorCheckInForm);
    },
    employeeKeys() {
      return this.formSettingKeys(this.editedItem.employeeCheckInForm);
    },
    temperatureMeasureScales,
    visitorKeys() {
      return this.formSettingKeys(this.editedItem.visitorCheckInForm);
    },
    meetingStartReminderTimeValues() {
      return ["PT24H", "PT12H", "PT6H", "PT3H"].map((val) => ({
        value: val,
        label: this.$t("buildings.edit.meetingStartReminderTime." + val),
      }));
    },
    settingsFormCustomFieldTypes() {
      return [
        {
          label: this.$t("buildings.edit.checkInFormSettings.field.type.TEXT"),
          value: "TEXT",
        },
        {
          label: this.$t(
            "buildings.edit.checkInFormSettings.field.type.BOOLEAN"
          ),
          value: "BOOLEAN",
        },
      ];
    },
    meetingEndReminderTimeValues() {
      return ["PT5M", "PT10M"].map((val) => ({
        value: val,
        label: this.$t("buildings.edit.meetingEndReminderTime." + val),
      }));
    },
    overdueAlertTimeValues() {
      return ["PT5M", "PT15M", "PT20M"].map((val) => ({
        value: val,
        label: this.$t("buildings.edit.overdueAlertTime." + val),
      }));
    },
    questionnairesValidityTimeoutValues() {
      const values = ["PT1H", "PT2H", "PT3H", "PT24H"];
      if (process.env.NODE_ENV !== 'production') {
        values.push('PT10M');
      }
      return values.map((val) => ({
        value: val,
        label: this.$t("buildings.edit.questionnairesValidityTimeout." + val),
      }));
    },
    autoCheckoutModeValues() {
      return ["TIMEOUT", "SPECIFIC_TIME"].map((val) => ({
        value: val,
        label: this.$t("buildings.edit.autoCheckoutModel." + val),
      }));
    },
    pinIdentificationMethodTypeValues() {
      return ["PERSONAL_PIN_AND_LASTNAME", "PIN_BUILDING_ONLY", "PERSONAL_PIN_AND_AUTOCOMPLETE"].map((val) => ({
        value: val,
        label: this.$t("buildings.edit.pinIdentificationMethodType." + val),
      }));
    },
    autoCheckOutTimeoutValues() {
      return ["PT1H", "PT2H", "PT3H"].map((val) => ({
        value: val,
        label: this.$t("buildings.edit.autoCheckOutTimeout." + val),
      }));
    },
    autoCheckOutTimeoutOrSpecificTImeRequired() {
      return (
        !this.editedItem.autoCheckOutTimeout &&
        !this.editedItem.autoCheckoutSpecificTime
      );
    },
    countries() {
      return this.$store.state.adminBuildings.countries;
    },
    timeZones() {
      return this.$store.state.adminBuildings.timeZones;
    },
    cameras() {
      return this.$store.state.cameras.buildingCameras;
    },
    devices() {
      let ids = this.editedItem.devicesIds;
      if (ids && ids !== DEVICES_SEP) {
        return ids.replace(/^;;;/, "").replace(/;;;$/, "").split(";;;").map(id => ({'name' : id}));
      }
      return [];
    },
    devicesCount() {
      let ids = this.editedItem.devicesIds;
      if (ids && ids !== DEVICES_SEP) {
        return ids.replace(/^;;;/, "").replace(/;;;$/, "").split(";;;").length;
      }
      return 0;
    },
    validDeviceAddress() {
      return (this.newDeviceName.match(/^[0-9]{1,3}(\.[0-9]{1,3}){3}$/));
    },
    availableLanguages,
    availableDateFormats() {
      return this.dateFormats;
    },
    availableTimeFormats() {
      return this.timeFormats;
    },
    camerasHeaders() {
      return [
        {
          text: this.$i18n.t("cameras.headers.name"),
          align: "left",
          sortable: true,
          value: "name",
        }, {
          text: this.$i18n.t("cameras.headers.authUser"),
          align: "left",
          sortable: false,
          value: "authUser",
        }, {
          text: this.$i18n.t("cameras.headers.ipAddress"),
          align: "left",
          sortable: false,
          value: "ipAddress",
        }, {
          text: this.$i18n.t("dataTable.ACTIONS"),
          value: "id",
          sortable: false,
          width: 100,
        },
      ];
    },
    devicesHeaders() {
      return [
        {
          text: this.$i18n.t("buildings.edit.kiosk.headers.name"),
          align: "left",
          sortable: true,
          value: "name",
        }, {
          text: this.$i18n.t("dataTable.ACTIONS"),
          value: "id",
          sortable: false,
          width: 100,
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      "editBuilding",
      "editGeneralSettings",
      "editCheckInFormSettings",
      "saveBuilding",
      "getPossibleValues",
      "saveBuildingQuestionnaires",
      "saveBuildingExitQuestionnaires",
      "saveBuildingDeviceTexts",
      "uploadCheckInLogo",
      "deleteCamera",
      "updateDevices"
    ]),
    questionnaireInfo(item) {
      return item.name + (item.description ? ' - ' + item.description : '');
    },
    async saveGeneral() {
      const isValid = await this.$refs.observerAddressContact.validate();
      if (isValid) {
        // Updating item
        try {
          if (this.editedItem.id) {
            await this.editBuilding(this.editedItem);
            this.tabsChanged.addressContact = false;
            this.$emit("on-save-success");
          } else {
            // Creating new item
            await this.saveBuilding(Object.assign({}, this.editedItem));
            this.tabsChanged.addressContact = false;
            this.$emit("on-save-success");
          }
          this.$nextTick(() => {
            this.$refs.observerAddressContact.reset();
          });
        } catch(e) {
          //
        }
      }
    },
    onCheckInLogoChange(e) {
      if (!this.$refs.fileInput) {
        this.checkInLogoUploadInvalid = false;
        return;
      }
      this.checkInLogoUploadInvalid =
        this.$refs.fileInput.$attrs.accept.indexOf(e.type) <= -1;
      this.$store.commit(types.CHECK_IN_LOGO_UPLOAD_PROGRESS, 0);
    },
    async upload() {
      if (this.editedItem.checkInLogo) {
        const payload = {
          id: this.editedItem.id,
          checkInLogo: this.editedItem.checkInLogo,
        };
        await this.uploadCheckInLogo(payload);
        this.$emit("on-save-success");
        console.log('after upload', this.editedItem.checkInLogoFileName, payload);
        this.editedItem.checkInLogoFileName = payload.checkInLogo.name;
      }
    },
    formSettingKeys(object) {
      const arr = Object.keys(object).filter(
        (key) =>
          !object[key].custom || (object[key].custom && object[key].enabled)
      );
      arr.sort((a, b) => {
        return object[a].order - object[b].order;
      });
      return arr;
    },
    openQuestionnairePreview(id) {
      this.questionnairePreviewDisplayed = true;
      this.questionnaireToPreview = this.availableQuestionnaires.find((q) => q.id === id);
    },
    closeQuestionnairePreview() {
      this.questionnairePreviewDisplayed = false;
      this.questionnaireToPreview = {};
    },
    fieldDescription(type, size) {
      return type === "TEXT"
        ? this.$t("buildings.edit.checkInFormSettings.field.type.TEXT_DESC", [size])
        : this.$t("buildings.edit.checkInFormSettings.field.type.BOOLEAN_DESC");
    },
    async saveGeneralSettings() {
      const isValid = await this.$refs.observerGeneral.validate();
      if (isValid) {
        // Updating item
        try {
          await this.editGeneralSettings(this.editedItem);
          this.$emit("on-save-success");
          this.tabsChanged.generalSettings = false;
          this.$nextTick(() => {
            this.$refs.observerGeneral.reset();
          });
        } catch (e) {
          //
        }
      }
    },
    async saveCheckInFormSettings() {
      const isValid = await this.$refs.observerCheckInFormSettings.validate();
      if (isValid) {
        try {
          await this.editCheckInFormSettings(this.editedItem);
          this.$emit("on-save-success");
          this.tabsChanged.checkInForm = false;
          this.$nextTick(() => {
            this.$refs.observerCheckInFormSettings.reset();
          });
        } catch (e) {
          //
        }
      }
    },
    async saveQuestionnaires() {
      await this.saveBuildingQuestionnaires(this.editedItem);
      this.$emit("on-save-success");
      this.tabsChanged.questionnaires = false;
    },
    async saveExitQuestionnaires() {
      await this.saveBuildingExitQuestionnaires(this.editedItem);
      this.$emit("on-save-success");
      this.tabsChanged.exitQuestionnaires = false;
    },
    async saveDeviceTexts() {
      try {
        await this.saveBuildingDeviceTexts(this.editedItem);
        this.$emit("on-save-success");
        this.tabsChanged.devices = false;
      } catch (e) {
        //
      }
    },
    close() {
      this.$nextTick(() => {
        this.$refs.observerAddressContact.reset();
      });
      this.tabsChanged = {
        generalSettings: false,
        addressContact: false,
        checkInForm: false,
        questionnaires: false,
        exitQuestionnaires: false
      };
      this.activeTab = 0;
      this.uploadCheckInLogoProgress = 0;
      this.$emit("on-close-modal");
    },
    addNewContractorField() {
      this.markCheckInFormTabChanged();
      this.addNewField(this.editedItem.contractorCheckInForm);
    },
    addNewVisitorField() {
      this.markCheckInFormTabChanged();
      this.addNewField(this.editedItem.visitorCheckInForm);
    },
    addNewEmployeeField() {
      this.markCheckInFormTabChanged();
      this.addNewField(this.editedItem.employeeCheckInForm);
    },
    addNewField(object) {
      const customFieldsLength = Object.keys(object).filter(
        (field) => object[field].custom && object[field].enabled
      ).length;
      if (customFieldsLength < 2) {
        const key = "custom" + (customFieldsLength + 1);
        object[key].enabled = true;
      }
    },
    removeCustomField(object, field) {
      this.markCheckInFormTabChanged();
      if (field === "custom1" && object["custom2"].enabled) {
        object["custom1"] = Object.assign(object["custom1"], object["custom2"]);
        object["custom2"].enabled = false;
        object["custom2"].label = "";
        object["custom2"].mandatory = false;
      } else {
        object[field].enabled = false;
        object[field].label = "";
        object[field].mandatory = false;
      }
    },
    onChangeTab(e) {
      if (e !== 0 && this.tabsChanged.addressContact) {
        this.$store.commit(types.WARNING, {
          msg: "buildings.edit.tabs.addressContactNotSaved",
        });
      } else if (e !== 1 && this.tabsChanged.generalSettings) {
        this.$store.commit(types.WARNING, {
          msg: "buildings.edit.tabs.settingsNotSaved",
        });
      } else if (e !== 2 && this.tabsChanged.checkInForm) {
        this.$store.commit(types.WARNING, {
          msg: "buildings.edit.tabs.checkInFormNotSaved",
        });
      } else if (e !== 3 && this.tabsChanged.questionnaires) {
        this.$store.commit(types.WARNING, {
          msg: "buildings.edit.tabs.questionnairesNotSaved",
        });
      } else if (e !== 4 && this.tabsChanged.exitQuestionnaires) {
        this.$store.commit(types.WARNING, {
          msg: "buildings.edit.tabs.questionnairesNotSaved",
        });
      }
    },
    onTemperatureScaleChanged() {
      this.markGeneralTabChanged();
      if(this.editedItem.minAllowedTemperature ) {
        if(this.editedItem.temperatureMeasureUnit === 'FAHRENHEIT') {
          this.editedItem.minAllowedTemperature = cToF(this.editedItem.minAllowedTemperature);
        } else {
          this.editedItem.minAllowedTemperature = fToC(this.editedItem.minAllowedTemperature);
        }
      }
      if(this.editedItem.maxAllowedTemperature ) {
        if(this.editedItem.temperatureMeasureUnit === 'FAHRENHEIT') {
          this.editedItem.maxAllowedTemperature = cToF(this.editedItem.maxAllowedTemperature);
        } else {
          this.editedItem.maxAllowedTemperature = fToC(this.editedItem.maxAllowedTemperature);
        }
      }
    },
    markGeneralTabChanged() {
      this.tabsChanged.generalSettings = true;
    },
    markAddressContactTabChanged() {
      this.tabsChanged.addressContact = true;
    },
    markCheckInFormTabChanged() {
      this.tabsChanged.checkInForm = true;
    },
    markQuestionnairesTabChanged() {
      this.tabsChanged.questionnaires = true;
    },
    markExitQuestionnairesChanged() {
      this.tabsChanged.exitQuestionnaires = true;
    },
    markDeviceTabChanged() {
      this.tabsChanged.devices = true;
    },
    showLogo() {
      this.logoDisplayed = true;
    },
    downloadCheckInQr() {
      let img = this.$refs.checkInQrCode.$el;
      this.downloadQrCode(img);
    },
    downloadRegistrationQr() {
      let img = this.$refs.registrationQrCode.$el;
      this.downloadQrCode(img);
    },
    downloadPinIdentificationQr() {
      let img = this.$refs.pinIdentificationQrCode.$el;
      this.downloadQrCode(img);
    },
    updateQuestionEnabled(item) {
      if (item.mandatory && ! item.enabled && item.enabledEditable) {
        item.enabled = true;
      }
      this.markCheckInFormTabChanged();
    },
    updateQuestionMandatory(item) {
      if (! item.enabled && item.mandatoryEditable) {
        item.mandatory = false;
      }
      this.markCheckInFormTabChanged();
    },
    downloadQrCode(img) {
      if (img) {
        let src = img.toDataURL();
        if (src != null) {
          let a = document.createElement("a");
          a.setAttribute("href", src);
          a.setAttribute("download", "qrcode.png");
          a.click();
        }
      }
    },
    async copyLink(link) {
      try {
        await navigator.clipboard.writeText(link);
        this.$store.commit(types.SUCCESS, {msg: "errors.SUCCESS_CLIPBOARD"});
      } catch (e) {
        this.$store.commit(types.ERROR, "FAILED_CLIPBOARD");
      }
    },
    copyCheckInQrCodeLink() {
      this.copyLink(this.checkInQrCodeValue(this.editedItem.qrCodeKey, true));
    },
    copyRegistrationQrCodeLink() {
      this.copyLink(this.registrationQrCodeValue(this.editedItem.qrCodeKey));
    },
    copyPinIdentificationQrCodeLink() {
      this.copyLink(this.pinIdentificationQrCodeValue(this.editedItem.qrCodeKey));
    },
    clipboardAvailable() {
      return navigator.clipboard !== undefined;
    },
    addCamera() {
      this.$refs.editCamera.open({buildingId: this.editedItem.id});
    },
    editCamera(camera, cameraHolder) {
      if (cameraHolder && cameraHolder.item) {
        this.$refs.editCamera.open(cameraHolder.item);
      } else {
        this.$refs.editCamera.open(camera);
      }
    },
    async callDeleteCamera(camera) {
      try {
        await this.deleteCamera(camera.id);
      } catch(e) {
        //
      }
    },
    async deleteDevice(device) {
      this.editedItem.devicesIds = this.editedItem.devicesIds.replace(DEVICES_SEP + device.name + DEVICES_SEP, DEVICES_SEP);
      await this.updateDevices({id: this.editedItem.id, devicesIds: this.editedItem.devicesIds});
      this.$emit("on-save-success");
    },
    async addDevice() {
      if (this.newDeviceName.match(/^[0-9]{1,3}(\.[0-9]{1,3}){3}$/)) {
        if (this.editedItem.devicesIds == null || this.editedItem.devicesIds.length === 0) {
          this.editedItem.devicesIds = DEVICES_SEP;
        }
        this.editedItem.devicesIds = this.editedItem.devicesIds + this.newDeviceName + DEVICES_SEP;
        await this.updateDevices({id: this.editedItem.id, devicesIds: this.editedItem.devicesIds});
        this.$emit("on-save-success");
        this.newDeviceName = '';
      }
    }
  },
  watch: {
    editedBuilding: {
      async handler() {
        this.editedItem = Object.assign({}, this.editedBuilding);
        this.editedItem.meetingStartReminderTime =
          this.editedItem.meetingStartReminderTime ||
          this.defaultData.meetingStartReminderTime;
        this.editedItem.defaultMeetingDuration =
          this.editedItem.defaultMeetingDuration ||
          this.defaultData.defaultMeetingDuration;
        this.editedItem.meetingEndReminderTime =
          this.editedItem.meetingEndReminderTime ||
          this.defaultData.meetingEndReminderTime;
        this.editedItem.overdueAlertTime =
          this.editedItem.overdueAlertTime || this.defaultData.overdueAlertTime;
        this.editedItem.temperatureMeasureUnit =
          this.editedItem.temperatureMeasureUnit ||
          this.defaultData.temperatureMeasureUnit;
        this.editedItem.timeZoneId = this.editedItem.timeZoneId || this.defaultData.timeZoneId;
        this.editedItem.defaultLocale =
            this.editedItem.defaultLocale ||
            this.defaultData.defaultLocale;
        if (! this.editedItem.timeFormat && this.timeFormats) {
          const defaultTimeFormat = this.timeFormats.filter((f) => f.default === true);
          if (defaultTimeFormat.length > 0) {
            this.editedItem.timeFormat = defaultTimeFormat[0].pattern;
          } else if (this.timeFormats.length > 0) {
            this.editedItem.timeFormat = this.timeFormats[0].pattern;
          }
        }
        if (! this.editedItem.dateFormat && this.dateFormats) {
          const defaultDateFormat = this.dateFormats.filter((f) => f.default === true);
          if (defaultDateFormat.length > 0) {
            this.editedItem.dateFormat = defaultDateFormat[0].format;
          } else if (this.dateFormats.length > 0) {
            this.editedItem.dateFormat = this.dateFormats[0].format;
          }
        }
        if (! this.editedItem.pinIdentificationMethodType) {
          this.editedItem.pinIdentificationMethodType = this.defaultData.pinIdentificationMethodType;
        }

        if (!this.editedItem.id) {
          this.editedItem.autoCheckOutTimeout = this.defaultData.autoCheckOutTimeout;
          this.autoCheckoutMode = "TIMEOUT";
          this.editedItem.questionnairesValidityTimeout = this.defaultData.questionnairesValidityTimeout;
        }

        this.autoCheckoutMode = this.editedItem.autoCheckOutTimeout
          ? "TIMEOUT"
          : "SPECIFIC_TIME";
        this.editedItem.contractorQuestionnaires = this.editedBuilding.contractorQuestionnaires;
        this.editedItem.visitorQuestionnaires = this.editedBuilding.visitorQuestionnaires;
        this.editedItem.employeeQuestionnaires = this.editedBuilding.employeeQuestionnaires;
        this.logoPath =
          axios.defaults.baseURL +
          "/public/buildings/" +
          this.editedItem.id +
          "/checkInLogoImage/" +
          this.editedItem.checkInLogoVersion;
      },
      deep: true,
      immediate: true,
    },
    autoCheckoutMode: {
      async handler() {
        if (this.autoCheckoutMode === "TIMEOUT") {
          this.editedItem.autoCheckoutSpecificTime = undefined;
        } else {
          this.editedItem.autoCheckOutTimeout = undefined;
        }
      },
      immediate: true,
    },
  },
};
