import api from "@/services/api/adminLogs";
import { handleError } from "@/utils/utils.js";
import * as types from "@/store/mutation-types";

const getters = {
  logs: (state) => state.logs,
  itemId: (state) => state.itemId,
  itemType: (state) => state.itemType
};

const actions = {
  getLogs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getLogs(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.LOGS, response.data);
            commit(types.LOGS_ITEM_ID, payload.id);
            commit(types.LOGS_ITEM_TYPE, payload.type);
            resolve();
          }
        })
        .catch((error) => {
          handleError(error, commit, reject);
        });
    });
  },
};

const state = {
  logs: "",
  itemId: null,
  itemType: null,
};
const mutations = {
  [types.LOGS](state, logs) {
    state.logs = logs;
  },
  [types.LOGS_ITEM_ID](state, id) {
    state.itemId = id;
  },
  [types.LOGS_ITEM_TYPE](state, type) {
    state.itemType = type;
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
