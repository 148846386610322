
export default [

{
    path: "/admin/spaces",
    name: "admin-spaces",
    meta: {
      requiresAuth: true,
      requiresTenant: true,
      grantedRoles: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPERVISOR"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-spacess" */ "@/components/adminSpaces/AdminSpaces.vue"
      ),
  }
,{
  path: "/admin/floor/print/:id",
  name: "admin-floorPrint",
  meta: {
    requiresAuth: true,
  },
  component: () =>
    import(/* webpackChunkName: "spaceprint" */ "@/components/adminFloors/AdminFloorPrint.vue"),
},


];