import * as types from "@/store/mutation-types";

import api from "@/services/api/spaceBooking";
import {buildSuccess, convertDateTimes, handleError, handleErrorCode} from "@/utils/utils.js";
/* eslint-disable */
const getters = {
    spaceBookings: (state) => state.spaceBookings,
    spaceBookingApproval: (state) => state.spaceBookingApproval,
    totalSpaceBookings: (state) => state.totalSpaceBookings,
    spaceBookingStats: (state) => state.spaceBookingStats,
    totalSpaceBookingStats: (state) => state.totalSpaceBookingStats,



    spaceBookingsByFloor: (state) => state.spaceBookingsByFloor,
    spaceBookingPeople: (state) => state.spaceBookingPeople,
    spaceBookingCreateStatus: (state) => state.spaceBookingCreateStatus,
    spaceBookingUpdataStatus: (state) => state.spaceBookingUpdataStatus,


};

const actions = {
    getSpaceBooking({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getSpaceBooking(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.SPACES, response.data.docs);
                        commit(types.TOTAL_SPACES, response.data.totalDocs);
                        resolve();
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doUpdateSpaceBooking({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .updateSpaceBooking(payload.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            commit(types.SPACEBOOKING_UPDATA_STATUS, false);
                            commit(types.WARNING, {msg:  'errors.'+response.data.errorCode});
                        } else {
                            commit(types.SPACEBOOKING_UPDATA_STATUS, true);
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    }
                })
                .catch((error) => {
                    commit(types.SPACEBOOKING_UPDATA_STATUS, false);
                    handleError(error, commit, reject);
                });
        });
    },
    doCreateSpaceBooking({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .createSpaceBooking(payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {

                            commit(types.WARNING, {msg:  'errors.'+response.data.errorCode});

                            commit(types.SPACEBOOKING_CREATE_STATUS, false);
                        } else {
                            commit(types.SPACEBOOKING_CREATE_STATUS, true);
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    } else {
                        commit(types.SPACEBOOKING_CREATE_STATUS, false);
                        handleError(response, commit, reject);
                    }
                })
                .catch((error) => {
                    commit(types.SPACEBOOKING_CREATE_STATUS, false);
                    handleError(error, commit, reject);
                });
        });
    },
    doDeleteSpaceBooking({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .deleteSpaceBooking(payload.id,payload.comment)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            {
                                msg: "common.DELETED_SUCCESSFULLY",
                            },
                            commit,
                            resolve
                        );
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    getSpaceBookingByFloor({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getSpaceBookingByFloor(payload.floorId, payload.startDate, payload.endDate)
                .then((response) => {
                    if (response.data && response.data.errorCode) {

                        commit(types.SPACEBOOKINGS_FLOOR, []);
                        commit(types.SPACEBOOKINGS, []);
                          

                    } else {
                        commit(types.SPACEBOOKINGS_FLOOR, response.data);

                        response.data.forEach(e => {
                            e.guests = e.guests!=null? JSON.parse(e.guests) : [];
                        });
                        

                        commit(types.SPACEBOOKINGS, response.data);
                        resolve();
                    }
                })
                .catch((error) => {
                    commit(types.SPACEBOOKINGS, []);
                    handleError(error, commit, reject);
                });
        });
    },
    getSpaceBookingApprovalById({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getSpaceBookingApprovalById(payload.id)
                .then((response) => {
                    if ((response.data && response.data.errorCode)||response.data=='') {
                        commit(types.SPACEBOOKING_APPROVAL, {});
                    } else {                  
                        const g=response.data.guests;
                        response.data.guests= g!=null? JSON.parse(g) : []
                        commit(types.SPACEBOOKING_APPROVAL, response.data);
                        resolve();
                    }
                })
                .catch((error) => {
                    commit(types.SPACEBOOKING_APPROVAL, {});
                    handleError(error, commit, reject);
                });
        });
    },
    getSpaceBookingPeople({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .getSpaceBookingPeople(payload)
                .then((response) => {
                    if (response.status === 200&& response.data.errorCode) {
                        commit(types.SPACEBOOKING_PEOPLE, []);
                        
                    }else {
                        commit(types.SPACEBOOKING_PEOPLE, response.data);
                        resolve();

                    }
                })
                .catch((error) => { 
                    commit(types.SPACEBOOKING_PEOPLE, []);
                    handleError(error, commit, reject);
                });
        });
    },
    doUpdateSpaceBookingApprove({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .updateSpaceBookingApprove(payload.id, payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            handleErrorCode(response.data.errorCode, commit, reject);
                        } else {
                            buildSuccess(
                                {msg: "common.SAVED_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    doSendSpaceBookingEmail({commit}, payload) {
        return new Promise((resolve, reject) => {
            api
                .doSendSpaceBookingEmail(payload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.errorCode) {
                            handleErrorCode(response.data.errorCode, commit, reject);
                        } else {
                            buildSuccess(
                                {msg: "common.SENT_EMAIL_SUCCESSFULLY"}, commit, resolve
                            );
                        }
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                });
        });
    },
    
};

const mutations = {
    [types.SPACEBOOKINGS](state, items) {
        convertDateTimes(items);
        state.spaceBookings = items;
    },
    [types.SPACEBOOKING_APPROVAL](state, items) {
        convertDateTimes(items);
        state.spaceBookingApproval = items;
    },

    [types.SPACEBOOKING_STATS](state, items) {
        convertDateTimes(items);
        state.spaceBookingStats = items;
    },
    [types.TOTAL_SPACEBOOKING_STATS](state, count) {
        state.totalSpaceBookingStats = count;
    },

    [types.SPACEBOOKINGS_FLOOR](state, items) {
        convertDateTimes(items);
        state.spaceBookingsByFloor = items;
    },
    [types.SPACEBOOKING_PEOPLE](state, items) {
        state.spaceBookingPeople = items;
    }
    ,
    [types.SPACEBOOKING_CREATE_STATUS](state, items) {
        state.spaceBookingCreateStatus = items;
    },
    [types.SPACEBOOKING_UPDATA_STATUS](state, items) {
        state.spaceBookingUpdataStatus = items;
    }

    
};

const state = {
    spaceBookings: [],
    totalSpaceBookings: 0,
    spaceBookingStats: [],
    totalSpaceBookingStats: 0,

    spaceBookingsByFloor:[],
    spaceBookingPeople:[],
    spaceBookingCreateStatus:false,
    spaceBookingUpdataStatus:false,
    spaceBookingApproval: {},



};

export default {
    state,
    getters,
    actions,
    mutations,
};
