<template>
  <div>
      <v-layout wrap>
        <v-container>
          <v-row no-gutters>
            <v-col cols="12" :sm="measurementEnabled && cameras ? 4 : 12">
              <v-flex xs12 class="headline">{{ checkIn ? $t("checkInOut.temperatureHeader.in") : $t("checkInOut.temperatureHeader.out") }}</v-flex>
              <v-flex xs12 v-if="measurementEnabled">
                <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                >
                  <v-text-field
                      v-model="tempMeasure1"
                      type="number"
                      :label="$t('checkInOut.temperatureMeasure1') + ' ['+ displayTempScaleUnit(loadedData.temperatureUnit)+ '] '"
                      :error="errors.length > 0"
                      :error-messages="errors[0]"
                      @change="emitData"
                  />
                </ValidationProvider>
              </v-flex>
              <v-flex xs12 v-if="measurementEnabled">
                <ValidationProvider v-slot="{ errors }" :rules="ruleForSecondMeasure(tempMeasure1, loadedData.minTemperature, loadedData.maxTemperature)">
                  <v-text-field
                      v-model="tempMeasure2"
                      type="number"
                      :disabled="disabledSecondMeasure(tempMeasure1, loadedData.minTemperature, loadedData.maxTemperature)"
                      :label="$t('checkInOut.temperatureMeasure2') + ' ['+ displayTempScaleUnit(loadedData.temperatureUnit)+ '] '"
                      :error="errors.length > 0"
                      :error-messages="errors[0]"
                      @change="emitData"
                  />
                </ValidationProvider>
              </v-flex>
            </v-col>
            <v-col sm="8" align-self="center" v-if="cameras" class="d-flex flex-column" style="align-items:center;">
              <v-select
                v-model="cameraId"
                dense
                :items="camerasList"
                :hide-details="true"
                item-text="name"
                item-value="id"
                :full-width="false"
                @change="changeCamera"
                class="mb-2 flex md8" />
              <v-flex style="position: relative;">
                <v-overlay opacity="0" :absolute="true" :value="loadingPicture" v-if="loadingPicture">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-overlay>
                <v-layout v-if="noPicture" class="camera-info">{{ $t("temperatureForm.noPicture")}}</v-layout>
                <v-img v-if="!noPicture" contain :src="pictureUrl" @error="failedLoadingPicture" @load="showLoadedPicture" @click="updatePictureUrl" class="camera-picture"></v-img>
              </v-flex>
              <v-layout justify-center>
                <v-btn class="white blue--text mt-1 btnExpired" @click="updatePictureUrl">{{ $t("temperatureForm.getReadings") }} </v-btn>
                <v-btn :disabled="lastReading == null" class="ml-3 white blue--text mt-1 btnExpired" @click="useLastReading">{{ $t("temperatureForm.useLastReadings") }} </v-btn>
              </v-layout>
            </v-col>
          </v-row>
          <v-row xs12>
            <v-col>
              <v-textarea
                  v-model="comments"
                  :label="$t('checkInOut.comments')"
                  autocomplete="off"
                  @change="emitData"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-layout>
  </div>
</template>

<script>
import api from "@/services/api/adminCameras";
import {handleSimpleError} from "@/utils/utils";

export default {
  name: "TemperatureForm",
  props: ["checkIn", "loadedData"],
  data: function () {
    return {
      comments: '',
      tempMeasure1: null,
      tempMeasure2: null,
      lastReading: null,
      lastPicture: 0,
      pictureUrl: '',
      loadingPicture: false,
      noPicture: false,
      cameras: null,
      cameraId: 0
    };
  },
  computed: {
    cameraStorageKey: function() {
      return (this.checkIn ? 'entry' : 'exit') + '-camera';
    },
    measurementEnabled: function() {
      return this.checkIn ?
          this.loadedData.checkInTemperatureMeasuresEnabled &&
          this.loadedData.allowCheckInManualTemperatureMeasure :
          this.loadedData.checkOutTemperatureMeasuresEnabled &&
          this.loadedData.allowCheckOutManualTemperatureMeasure;
    },
    camerasList: function() {
      let ids = Object.keys(this.cameras);
      let list = [];
      for (let i = 0; i < ids.length; i++) {
        list.push({"id": ids[i], "name":this.cameras[ids[i]]});
      }
      return list;
    }
  },
  watch: {
    loadedData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.loadedData.cameras == null) {
          this.cameras = null;
          this.cameraId = null;
        } else {
          let newCameras = this.loadedData.cameras;
          // console.log('entries', newCameras);
          if (newCameras != null) {
            if (newCameras['ENTRY'] || newCameras['EXIT']) {
              if (this.checkIn) {
                newCameras = newCameras['ENTRY'];
              } else {
                newCameras = newCameras['EXIT'];
              }
            }
          }
          if (this.cameraId == null || this.cameras !== newCameras) {
            this.cameras = newCameras;
            let cameraKeys = Object.keys(this.cameras);
            if (cameraKeys.length > 0) {
              let storedCameraId = window.localStorage.getItem(this.cameraStorageKey);
              if (storedCameraId && cameraKeys.indexOf(storedCameraId) > -1) {
                this.cameraId = storedCameraId;
              } else {
                this.cameraId = cameraKeys[0];
              }
              // console.log('selected', this.cameraId);
              this.updatePictureUrl();
            } else {
              this.cameras = null;
              this.cameraId = null;
            }
          }
        }
      }
    }
  },
  methods: {
    changeCamera(id) {
      this.cameraId = id;
      window.localStorage.setItem(this.cameraStorageKey, id);
      this.updatePictureUrl();
    },
    updatePictureUrl() {
      // console.log('updatepictureurl', this.cameraId);
      if (this.cameraId > 0) {
        this.loadingPicture = true;
        this.lastPicture = (new Date()).getTime();
        this.pictureUrl = (process.env.VUE_APP_API_BASE_URL || "") + '/camera/picture/' + this.cameraId + '?x=' + this.lastPicture;
        this.getTemperatureInfo();
      }
    },
    showLoadedPicture() {
      this.noPicture = false;
      this.loadingPicture = false;
    },
    failedLoadingPicture() {
      this.noPicture = true;
      this.loadingPicture = false;
    },
    async getTemperatureInfo() {
      this.lastReading = null;
      await api.getTemperatureInfo(this.cameraId)
            .then((response) => {
              if (response.status === 200) {
                this.lastReading = response.data.ThermometryRulesTemperatureInfoList.
                    ThermometryRulesTemperatureInfo[0].maxTemperature;
              }
            })
            .catch((error) => {
              handleSimpleError(error);
            });
    },
    useLastReading() {
      if (this.lastReading != null) {
        if (!this.tempMeasure1 || this.tempMeasure2 ||
            this.loadedData.minTemperature <= this.tempMeasure1 && this.loadedData.maxTemperature >= this.tempMeasure1) {
          this.tempMeasure1 = this.lastReading;
          this.tempMeasure2 = null;
        } else {
          this.tempMeasure2 = this.lastReading;
        }
        this.$emit('on-reading', this.tempMeasure1, this.tempMeasure2, this.comments);
        this.lastReading = null;
      }
    },
    emitData() {
      // console.log('emitData', arguments);
      this.$emit('on-reading', this.tempMeasure1, this.tempMeasure2, this.comments);
    }
  }
}
</script>

<style scoped>

</style>