import * as types from "@/store/mutation-types";

const getters = {
  showWarningMessage: (state) => state.showWarningMessage,
  warningMessage: (state) => state.warningMessage,
  warningMessageParams: (state) => state.warningMessageParams,
  warningMessageTimeout: (state) => state.warningMessageTimeout,
};

const mutations = {
  [types.WARNING](state, payload) {
    if (payload === null) {
      state.showWarningMessage = false;
      state.warningMessage = null;
      state.warningMessageParams = [];
    } else {
      state.showWarningMessage = true;
      state.warningMessageTimeout =
        payload.timeout === 0 ? 0 : payload.timeout || 6000;
      state.warningMessage = payload.msg;
      if (payload.params) {
        state.warningMessageParams = payload.params;
      }
    }
  },
  [types.SHOW_WARNING](state, payload) {
    state.showWarningMessage = !!payload;
  },
};

const state = {
  showWarningMessage: false,
  warningMessage: null,
  warningMessageParams: [],
  warningMessageTimeout: -1,
};

export default {
  state,
  getters,
  mutations,
};
