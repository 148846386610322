<template>
  <v-text-field
    :id="id"
    @keypress="isAllowed($event)"
    @input="onInput"
    v-model="zip"
    :counter="counter"
    ref="zipInput"
    :value="value"
    :name="name"
    :label="label"
    :error="error"
    :error-messages="errorMessages"
    autocomplete="off"
  />
</template>

<script>
export default {
  name: "ZipInput",
  props: {
    id: {
      type: String,
      default: "zip",
    },
    counter: {
      type: Number,
      default: 14,
    },
    name: {
      type: String,
      default: "zip",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [Array, String],
    },
  },
  data() {
    return {
      zip: "",
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    "zip.valid": function (value) {
      if (value) {
        this.phone = value;
      }
      this.$emit("validate", this.zip);
      //this.$emit('onValidate', this.phoneObject); // Deprecated
    },
    value() {
      this.zip = this.value;
    },
  },
  methods: {
    isAllowed: function (evt) {
      /*if(this.zip && this.zip.length >= 10) {
        evt.preventDefault();
      }*/
      evt = evt ? evt : window.event;
      var code = evt.which ? evt.which : evt.keyCode;
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123) && // lower alpha (a-z)
        code !== 32 &&
        code &&
        code !== 45 &&
        code !== 46 // space, dot, hyphen
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onInput() {
      this.$emit("input", this.zip);
    },
  },
  created() {
    if (this.value) {
      this.zip = this.value.trim();
    }
  },
};
</script>

<style scoped></style>
